calao-inline-field-layout {
	ion-checkbox {
		--size: 24px !important;
		--border-color: var(--ion-color-light-shade) !important;
	}

	calao-inline-field-layout-content {
		.ellipsis {
			width: 100%;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 1;
			overflow: hidden;
		}

		span.contact-name {
			padding-top: 3px !important;
			font-size: 14px !important;
		}

		span {
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 1;
			overflow: hidden;
		}

		input {
			height: 20px !important;
		}
	}

	calao-inline-field-layout-bottom-content {
		osapp-date-time.flex-end {
			width: 100%;

			ion-item ion-label {
				display: flex !important;
				justify-content: end !important;
			}
		}
	}
}

.inline-textarea {
	.content {
		align-items: start !important;

		.icon-container {
			padding-top: 10px;
			padding-bottom: 10px;
		}
	}

	ion-textarea {
		margin: 0 !important;
		padding: 10px 0 0 !important;
		--padding-top: 0 !important;
		--padding-bottom: 0 !important;
	}
}

.inline-input {
	ion-item {
		width: 100%;
	}
}

.inline-form {

	padding-bottom: 60px;

	input::placeholder,
	textarea::placeholder {
		font-style: normal !important;
		font-family: "New Hero" !important;
	}

	input,
	textarea {
		font-family: "New Hero Bold" !important;
	}

	textarea {
		font-size: 14px !important;
		--placeholder-color: var(--ion-color-medium-shade);
		--placeholder-opacity: 1;
	}

	.title-input {
		font-size: 16px !important;
	}
}
