/* ionic.theme.default.scss /////
 * Description : Fichier contenant les styles de modèles génériques applicables à plusieurs pages
*/

/*#region Changement de la CSS déjà écrite */

// Région toolbar
ion-title {
	font-size: 16px;
}

calao-database-sync-status-icon .in-toolbar {
	background: rgb(200, 200, 200);
	background: radial-gradient(
		circle,
		rgb(220, 218, 218) 0%,
		var(--ion-color-background) 50%,
		var(--ion-color-background) 80%
	);
}

calao-database-sync-status ion-badge {
	background: var(--ion-color-primary-contrast);
}

.search-box .tags-list-container {
	padding: 0 10px 10px 10px;
}

.type-selector {
	.tags-list-container {
		padding-right: 0 !important;
	}

	drag-scroll {
		margin: 0 !important;
	}
}

calao-sub-header {
	ion-toolbar {
		--min-height: 36px;
		--max-height: 36px;

		.sub-header-end-buttons {
			margin-right: 10px !important;
		}

		.calao-sub-header-start-buttons,
		calao-sub-header-end-buttons {
			display: flex;
			gap: 12px;
		}

		.sub-header-buttons {
			calao-sub-header-button {
				ion-button {
					width: 32px !important;
					height: 32px !important;
					color: var(--ion-color-primary-contrast);
					margin-left: 0 !important;
					margin-right: 0 !important;

					ion-icon {
						font-size: 16px !important;
					}
				}
			}
		}
	}
}

calao-sub-header .primary-btn {
	ion-button {
		--background: var(--ion-color-primary) !important;
	}
}

calao-sub-header .delete-btn {
	ion-button {
		--background: var(--ion-color-danger) !important;
	}
}

// Région tab-bar
.center-tab-bar-wrapper {
	calao-tab {
		width: 25%;
	}
}

@media (min-width: 576px) {
	.center-tab-bar-wrapper {
		calao-tab {
			width: auto;
		}
	}
}

// Bouton menu principal
.first-name {
	text-transform: lowercase;
	margin: 0;

	&::first-letter {
		text-transform: capitalize;
	}
}

ion-item {
	ng-component {
		width: 100%;
	}
}

ion-list-header.ios {
	ion-label {
		margin: auto;
		font-size: 16px;
		font-weight: 500;
	}
}

ion-note.synchro-label.ios {
	margin: auto;
	font-size: 16px;
	font-weight: 400;
}

.marg-container {
	margin-right: 10px;
	margin-left: 10px;
	width: auto;
}

.border-item-top {
	border-radius: 8px 8px 0 0;
}

.border-item-btm {
	border-radius: 0 0 4px 4px;
}

ion-list.marg-container {
	border-radius: 20px;
	box-shadow: var(--calao-shadow);
	padding: 0;
}

calao-conversation-list ion-list.marg-container {
	box-shadow: var(--calao-shadow);
}

calao-conversation-list div.marg-container {
	padding-bottom: 10px;
}

ion-virtual-scroll.marg-container {
	border-radius: 20px;
	box-shadow: var(--calao-shadow);
	padding: 0;
}

.border-radius-bottom,
.border-radius-bottom
	calao-virtual-scroll
	.calao-virtual-scroll-wrapper
	cdk-virtual-scroll-viewport
	.cdk-virtual-scroll-content-wrapper {
	border-radius: 0 0 14px 14px !important;
}

trade-invoicing cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper {
	border-radius: 0;
}

.calao-virtual-scroll-wrapper {
	border-radius: 20px !important;
	box-shadow: none !important;
}

trade-event-prestations-modal .calao-virtual-scroll-wrapper {
	background: transparent;
	box-shadow: none;
	padding: 8px;

	.cdk-virtual-scroll-content-wrapper {
		padding-bottom: 8px;
	}
}

ion-virtual-scroll {
	box-shadow: var(--calao-shadow);
	width: auto !important;
	margin-right: 10px;
	margin-left: 10px;
	border-radius: 20px;
}

ion-list.list-md {
	padding-top: 0;
	padding-bottom: 0;
}

ion-slide contacts-list ion-list {
	box-shadow: none;
	margin: 0;
	border-radius: 0;
}

ion-slide .events-list {
	margin: 10px;
}

ion-slide contacts-list ion-list.list-md {
	margin: 0;
}

ion-slide trade-conversations-list ion-list {
	margin-top: 0;
}

ion-slide trade-conversations-list ion-list.list-md {
	margin-top: 0;
}

ion-slide transmissions-list ion-list {
	margin-top: 0;
}

ion-slide transmissions-list ion-list.list-md {
	margin-top: 0;
}

trade-contact,
trade-group {
	.color-field-area {
		width: 100%;
		margin-bottom: 8px;

		span {
			width: 110px;
			min-width: 110px;
			max-width: 110px;
			font-family: "New Hero Bold" !important;
			font-size: 14px !important;
			color: var(--ion-color-dark) !important;
		}
	}

	.color-picker-wrapper {
		padding-left: 0 !important;

		ion-note {
			font-family: "New Hero Bold" !important;
			font-size: 14px !important;
			color: var(--ion-color-dark) !important;
		}
	}

	.groups-list ion-list {
		display: flex;
		flex-direction: row;
		align-items: center;

		ion-list-header {
			width: 110px;
			min-width: 110px;
			max-width: 110px;

			ion-label {
				font-family: "New Hero Bold" !important;
				font-size: 14px !important;
				color: var(--ion-color-dark) !important;
			}
		}
	}

	form {
		height: 100%;
	}

	.form-toolbar {
		height: auto !important;
	}

	.pad-infos {
		margin: 20px 10px;
		padding-bottom: 24px !important;
		background: white;
		box-shadow: var(--calao-shadow);
		border-radius: 20px;
		padding: 10px;
		width: auto;
	}

	.width100pc.dis-flex.pad-infos ion-item {
		.groups-list-visu {
			box-shadow: none;
			border-radius: 0;
			border: none;
			padding: 2px 0;
			text-transform: capitalize;
			font-size: 14px;
			color: var(--ion-color-osapp-element);
		}

		.marg-infos {
			max-width: 100%;
		}

		ion-list-header ion-label.md {
			margin: auto;
		}

		ion-label.md {
			margin-top: -16px;
		}

		ion-label.no-groups {
			margin: auto;
			padding-top: 10px;
		}
	}

	.txt-infos {
		color: var(--ion-color-dark);
	}
}

ion-slide .width100pc.dis-flex.pad-infos {
	background: white;
	box-shadow: var(--calao-shadow);
	border-radius: 8px;
	margin: 10px;
	width: auto;
}

.sc-ion-searchbar-ios-h {
	--background: white;
}

.searchbar-input-container.sc-ion-searchbar-ios {
	box-shadow: var(--calao-shadow);
	border-radius: 8px;
	height: 42px;
}

.sc-ion-searchbar-md-h {
	--border-radius: 8px;
}

.search-bar-area .mat-mdc-form-field-wrapper {
	margin: 0 !important;
	margin-bottom: 10px !important;
}

.header-list {
	ion-row {
		background: white;
		border-radius: 8px 8px 0 0;
	}

	ion-col {
		margin: auto;
		justify-content: center;
		display: flex;

		a {
			align-items: center !important;
		}
	}

	ion-col:last-of-type {
		display: block;

		a {
			display: flex;

			ion-icon {
				vertical-align: middle;
				margin-left: auto;
			}
		}
	}

	.grid-content ion-col:last-of-type {
		text-align: left;
	}
}

ion-content {
	--background: var(--ion-color-background);
	--keyboard-offset: 0 !important;
}

ion-menu ion-content {
	--background: white;
}

super-tabs-container ion-item {
	--background: transparent;
}

ion-virtual-scroll ion-item {
	--background: white;
}

ion-slide transmissions-list ion-item {
	--background: transparent;
}

ion-list ion-item {
	--background: white;
	background: white;
}

ion-slide {
	ion-list {
		background: transparent !important;
	}
}

.transparent {
	ion-list {
		background: transparent !important;
	}
}

ion-grid.table.table-striped .list {
	margin: 0;
	padding: 0;
	background: white;
	border-radius: 0 0 4px 4px;
	box-shadow: var(--calao-shadow);
}

/* Apparence champs formulaires **/
// Mode visu formulaires

calao-event-participants-field ion-button ion-icon {
	color: white !important;
}

form a,
form span {
	color: var(--ion-color-dark);
	font-size: 14px;
}

trade-event-occurrence,
trade-task-occurrence,
calao-formly-wrapper,
calao-form-modal,
trade-contact,
trade-group {
	background: var(--ion-color-primary-contrast);

	calao-sub-header ion-header ion-toolbar:first-of-type {
		padding-top: 0 !important;
	}

	form {
		padding: 0 !important;

		.dis-flex {
			formly-group {
				display: flex;
			}
		}

		.flex-col {
			formly-group {
				flex-direction: column;
			}
		}

		.flex-row {
			formly-group {
				flex-direction: row;
			}
		}

		.form-header {
			background: var(--ion-color-background);
			padding: 24px 10px 5px;

			formly-group {
				align-items: center;
			}

			.picture-edit {
				background: var(--ion-color-primary-contrast, white) !important;
				height: 84px !important;
				width: 84px !important;
				margin: auto;
				border-radius: 14px !important;
				min-height: 84px !important;
				max-height: 84px !important;

				ion-icon {
					font-size: 40px;
				}
			}

			.icon-area,
			.icon-text-area formly-group {
				width: 84px;
				height: 84px;
				background: var(--ion-color-primary-contrast);
				box-shadow: var(--calao-shadow);
				border-radius: 14px;

				.img {
					background: none !important;
				}

				ion-item {
					--inner-padding-end: 0 !important;
				}

				ion-icon {
					font-size: 40px;
					margin: 22px;
				}
			}

			.icon-text-area formly-group {
				display: flex;
				flex-direction: column;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				ion-icon {
					margin: 4px auto;
				}

				span {
					font-size: 12px;
					white-space: pre-wrap;
					width: 100%;
					max-width: 84px;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 1;
					overflow: hidden;
					padding: 0 4px;
					text-align: center;
				}
			}

			.title-area {
				margin-left: 24px;

				.title-label-group formly-group {
					flex-direction: row !important;
				}

				formly-group {
					flex-direction: column;
				}

				.title-label {
					span {
						font-family: "New Hero Bold";
						font-size: 18px;
						width: 100%;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
						overflow: hidden;
					}

					ion-icon {
						color: var(--ion-color-dark) !important;
						width: 16px !important;
						align-self: normal !important;
					}
				}

				span {
					font-size: 14px;
				}

				ion-icon {
					font-size: 20px;
					color: var(--ion-color-tertiary);
				}
			}
		}

		.form-content.doc-content {
			.section-title {
				width: 100% !important;
				min-width: 100% !important;
				max-width: 100% !important;

				span {
					font-family: "New Hero Bold" !important;
				}
			}

			.icon-details {
				width: 5rem !important;

				formly-group {
					display: flex;
					flex-direction: column;

					.icon-line,
					.icon-label {
						margin: auto;
					}

					span {
						font-size: 10px !important;
						text-align: center;
					}
				}
			}

			.text-after-icon {
				width: calc(100% - 5rem);

				formly-group {
					flex-direction: column;
					align-items: flex-start;
					margin: 4px;
				}
			}

			.bottom-line {
				padding-bottom: 14px;
				margin-bottom: 14px;
				border-bottom: 1px var(--ion-color-light-shade) solid;
			}

			.document-title span {
				font-size: 16px !important;
			}

			.html-content .html-editor-style {
				margin: 0 !important;
				width: 100%;
				font-size: 14px;
				font-family: "New Hero";
				color: var(--ion-color-dark);

				h1,
				h2,
				h3,
				h4,
				h5,
				h6 {
					font-size: 14px !important;
				}
			}
		}

		.form-content {
			background: var(--ion-color-primary-contrast);
			padding: 10px;
			margin-bottom: 84px !important;
			width: 100%;

			formly-group {
				flex-direction: column;
			}

			.content-line {
				padding: 4px 0;

				.section-title {
					width: 110px;
					min-width: 110px;
					max-width: 110px;
				}

				.completed-field {
					width: calc(100% - 110px);

					ion-label {
						font-size: 14px !important;
						color: var(--ion-color-dark);
					}

					.ctn-liste-gallery.thumbnails {
						justify-content: flex-start !important;
					}
				}

				formly-group {
					flex-direction: row;
				}

				ion-icon {
					font-size: 24px;
					padding: 6px;
					border-radius: 50px;
					color: var(--ion-color-dark);
				}

				.ctn-phones {
					padding-bottom: 4px;
					ion-icon {
						font-size: 20px !important;
						border-radius: 0 !important;
						padding: 0 !important;
						color: var(--ion-color-tertiary) !important;
					}
				}

				.width10pc {
					width: 40px !important;
				}

				.width90pc formly-group {
					flex-direction: column;
					align-items: flex-start;
					padding-left: 16px;
				}

				.notes-area span {
					font-size: 14px;
					color: var(--ion-color-medium);
					font-style: italic;
				}

				calao-recurrences-field div {
					font-size: 14px;
				}
			}

			.content-line.gallery .thumbnails {
				justify-content: start !important;
			}

			.progress-field .progress-bar-area {
				ion-label {
					display: none;
				}

				ion-progress-bar {
					display: flex;
					align-items: center;
					width: 200px;
				}
			}
		}

		.business-form-content {
			.no-border {
				border-top: none !important;
			 }

			.content-line {
				border-top: 1px solid var(--ion-color-light-shade);
			}
		}
	}

	.pad-contacts {
		padding: 0 !important;
	}

	.txt-contacts-list {
		color: var(--ion-color-dark) !important;
	}

	.empty-gallery p {
		font-size: 14px !important;
		margin: 0 !important;
		color: var(--ion-color-dark);
	}
}

.custom-corners-bloc .input {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	span {
		font-size: 16px !important;
		margin-top: 10px;
		margin-right: 12px;
		margin-bottom: 5px;
	}
}

calao-formly-wrapper,
trade-contact,
trade-group {
	form {
		background: var(--ion-color-primary-contrast);
		padding: 10px;
		margin: 0 !important;

		.ctn-customer-top formly-group {
			display: flex;
			flex-direction: column;

			.ctn-form-header formly-group,
			.ctn-actions-btn formly-group {
				flex-direction: row;

				.title-area formly-group {
					flex-direction: column;
				}
			}

			.ctn-actions-btn formly-group {
				align-items: center;
				justify-content: flex-start;
				flex-wrap: wrap;
			}
		}

		.padding-top-edit {
			padding-top: 20px;
		}
	}
}

.label-with-divider .divider {
	visibility: hidden;
}

trade-contact,
trade-group,
#contact-form {
	.img-area {
		margin: 8px 0;
	}

	.bloc-area {
		margin: 8px 0;
		padding: 12px 8px;

		.chips-ctn {
			margin: 0 10px;
		}

		.cy-customer-gender {
			.mat-mdc-form-field-wrapper {
				padding: 2px 0;
			}
			.mdc-evolution-chip-set__chips {
				justify-content: center;
			}
		}

		.zipcode-area {
			.mat-form-field-appearance-outline {
				margin-right: 4px;
			}
		}

		.factu-key {
			font-size: 16px;
			color: var(--ion-color-medium);
			padding-top: 2px;
			font-family: "New Hero";
		}
	}

	.gallery {
		ion-label {
			color: var(--ion-color-medium) !important;
			font-size: 12px !important;
			white-space: inherit !important;
		}
	}

	.tags {
		.mat-mdc-form-field-infix {
			padding-top: 14px;
		}
	}
}

.form-toolbar {
	background: white;
}

.section-title ion-label {
	font-size: 14px !important;
	display: contents !important;
}

.address-fields {
	padding-left: 28px;
}

.city-label span,
.selector-zipcode span {
	white-space: pre-wrap;
	width: 100%;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	overflow: hidden;
}

.number-field ion-item {
	align-items: end;
}

.ctn-align-fields formly-group {
	display: flex;
	flex-direction: row !important;
}

trade-event-occurrence {
	.form-header {
		ion-icon {
			color: var(--ion-color-event) !important;
		}
	}

	.icon-line {
		ion-icon {
			background: var(--ion-color-event-tint);
		}
	}
}

trade-task-occurrence {
	.form-header {
		ion-icon {
			color: var(--ion-color-task) !important;
		}
	}

	.icon-line {
		ion-icon {
			background: var(--ion-color-task-tint);
		}
	}
}

trade-reminder-occurrence {
	.icon-line {
		ion-icon {
			background: var(--ion-color-reminder-icon);
		}
	}
}

trade-task-occurrence,
trade-event-occurrence {
	.form-header {
		formly-group {
			display: flex;
			flex-direction: column;
		}

		.form-header-row {
			formly-group {
				flex-direction: row;
			}
		}
	}
}

.doc-content {
	.icon-line {
		ion-icon {
			background: var(--ion-color-background);
			margin: 0 !important;
		}
	}
}

calao-formly-wrapper,
trade-contact,
trade-group {
	.form-header .opacity60 {
		ion-icon {
			color: var(--ion-color-tertiary);
		}
	}
}

@media screen and (min-width: 620px) {
	trade-group .form-header formly-group,
	trade-contact .form-header formly-group,
	group-details .form-header formly-group,
	calao-formly-wrapper .form-header formly-group,
	trade-event-occurrence .form-header formly-group,
	trade-task-occurrence .form-header formly-group {
		max-width: 620px;
		margin: auto !important;
	}
}

// Mode édition formulaires
calao-formly-wrapper form,
calao-form form {
	.marg-infos {
		max-width: 100%;
		width: 100%;
	}

	.bloc-area {
		display: flex;
		flex-direction: column;
		max-width: 620px;
		margin: auto !important;
		padding-top: 10px;

		&:first-of-type {
			padding-top: 0;
		}

		.pad-contacts {
			padding-top: 0 !important;
		}

		.title-group {
			margin-top: 0 !important;
		}
	}
}

calao-formly-wrapper {
	height: 100%;
	background: var(--ion-color-primary-contrast);
}

calao-formly-wrapper.no-margin-bottom {
	.bloc-area:last-of-type {
		margin-bottom: 0 !important;
	}
}

trade-event-occurrence,
trade-task-occurrence {
	background: var(--ion-color-background);
}

trade-task-occurrence .bloc-area {
	padding-top: 8px;
}

calao-formly-wrapper form,
trade-contact calao-form form,
trade-group calao-form form {
	.bloc-area {
		padding: 8px 10px 0 10px;
	}

	.bloc-area:first-of-type {
		padding-top: 10px;
	}
}

.checkbox-no-margin {
	formly-field-ion-select {
		margin-left: 0px !important;
		margin-right: 0px !important;

		ion-label {
			font-size: 14px !important;
			padding-left: 4px;
			color: var(--ion-color-dark);
		}
	}
}

.bloc-area {
	formly-field-ion-select {
		display: flex;
		margin-right: 8px;

		ion-item {
			width: 100%;
			--background-focused: transparent;
			--background-hover: transparent;
			--background-activated: transparent;
			--ripple-color: transparent;
		}

		.chips-ctn {
			margin: 0 !important;
		}
	}

	contacts-list .title-group,
	calao-groups-checklist ion-list-header ion-label {
		font-family: "New Hero Bold" !important;
		font-size: 14px !important;
		color: var(--ion-color-dark) !important;
		margin: 6px 0;
	}

	.edit-check-groups.groups-list-area {
		padding: 0 !important;
	}

	.state ion-item,
	formly-field-ion-select ion-item {
		--inner-padding-end: 0;
	}
}

.bloc-area:last-of-type {
	border-bottom: none;
	margin-bottom: 65px !important;
}

.bloc-area:last-of-type.gallery {
	margin-bottom: 96px !important;
}

.bloc-area.date-area {
	.date-field .mat-mdc-form-field-wrapper {
		padding-bottom: 0;
	}
}

.bloc-area.repeat-area {
	formly-group {
		display: flex;
		flex-direction: row;
	}
}

.activation-wrapper ion-note {
	font-family: "New Hero";
	font-size: 12px;
}

.label-group {
	font-family: "New Hero Bold" !important;
	font-size: 18px !important;
	color: var(--ion-color-dark) !important;
}

ion-textarea {
	padding: 8px 0 !important;
}

.mat-form-field-appearance-outline input::placeholder {
	font-style: normal;
}

.inline-form {
	display: flex;
	flex-direction: column;
	max-width: 620px;
	margin: auto !important;
	padding: 10px;
}

// Arrondis champs outline des formulaires
.rounded-corners-form {
	background: var(--ion-color-primary-contrast, white);

	.picture-edit {
		background: var(--ion-color-background) !important;
		height: 8rem !important;
		width: 8rem !important;
		margin: auto;
		border-radius: 50%;
		min-height: 8rem !important;
		max-height: 8rem !important;

		ion-icon {
			color: var(--ion-color-dark);
			font-size: 52px;
		}
	}
}

.rounded-corners-form-business {
	.picture-edit {
		border-radius: 10%;
	}
}

.rounded-corners-form,
calao-filter-bar-item {
	.mat-form-field-appearance-outline .mdc-notched-outline__leading,
	.mat-form-field-appearance-outline .mdc-notched-outline__trailing {
		width: 80px !important;
	}

	.mat-form-field-appearance-outline .mdc-notched-outline__leading {
		border-radius: 80px 0 0 80px !important;
	}

	.mat-form-field-appearance-outline .mdc-notched-outline__trailing {
		border-radius: 0 80px 80px 0 !important;
	}

	.custom-corners-bloc .mat-form-field-appearance-outline .mdc-notched-outline__leading,
	.mat-form-field-appearance-outline .mdc-notched-outline__trailing {
		width: 14px !important;
	}

	.custom-corners-bloc .mat-form-field-appearance-outline .mdc-notched-outline__leading {
		border-radius: 14px 0 0 14px !important;
	}

	.custom-corners-bloc .mat-form-field-appearance-outline .mdc-notched-outline__trailing {
		border-radius: 0 14px 14px 0 !important;
	}
}

.alert-button span {
	font-size: 14px;
}

ion-fab-button {
	font-family: "New Hero Bold";
	--box-shadow: var(--calao-shadow);

	ion-label {
		font-size: 14px;
	}

	ion-icon {
		font-size: 36px;
	}
}

.fab-label {
	--border-radius: 50px;
	border-radius: 50px;
	box-shadow: var(--calao-shadow);
	width: 140px;

	.label-14 {
		font-size: 14px !important;
	}

	&.fab-end-task-button {
		width: 120px !important;
		height: 40px;
		margin-right: 0 !important;
		margin: auto;

		ion-icon {
			width: 16px !important;
			height: 16px !important;
			padding-right: 4px;
		}
	}

	&.fab-close-button {
		width: 50px !important;
		height: 50px;
		margin-right: 0 !important;
		margin-left: 12px;

		ion-icon {
			font-size: 24px;
		}
	}
}

.rounded-corners-form osapp-date-time ion-label,
.state ion-select {
	font-family: "New Hero Bold" !important;
	color: var(--ion-color-dark) !important;
}

.periodicity .date-field osapp-date-time ion-item {
	border: 1px solid var(--ion-color-light-shade);
	border-radius: 50px;
	height: 40px;
}

.periodicity ion-radio-group .date-field ion-item,
.periodicity .date-field.start-date osapp-date-time ion-item {
	margin: 6px 0;
}

.params-repeat {
	osapp-selector {
		ion-select {
			padding: 0 0 0 10px !important;
		}
	}

	ion-input {
		font-family: "New Hero Bold";
		color: var(--ion-color-dark);

		input {
			padding: 10px !important;
		}
	}
}

.week-days-wrapper {
	ion-note {
		font-size: 14px !important;
		padding-right: 8px;
	}

	drag-scroll {
		margin-right: 0 !important;
	}

	.drag-scroll-content {
		display: flex !important;
		flex-direction: row !important;
		flex-wrap: wrap !important;
	}

	osapp-selector mat-chip-option {
		height: 40px;
		width: 32px;
		border-radius: 50px;
		margin: 2px !important;

		span {
			margin: auto;
		}
	}
}

.drag-scroll-content {
	width: 100% !important;
}

.custom-padding-bottom {
	padding-bottom: 14px !important;
}

form ion-item {
	--background: transparent;
}

.item-divider {
	background-color: white !important;
}

.marge-last {
	margin-bottom: 24px;
	display: block;
}

mat-expansion-panel {
	border-radius: 8px !important;
}

::-webkit-input-placeholder {
	font-style: italic;
}

:-moz-placeholder {
	font-style: italic;
}

::-moz-placeholder {
	font-style: italic;
}

:-ms-input-placeholder {
	font-style: italic;
}

.button-md {
	box-shadow: none !important;
}

.itemb {
	height: 4rem !important;
}

.txt-l {
	text-align: left !important;
}

.margelect {
	margin: 0 0.5rem 0 0;
}

.marge-nom {
	padding-left: 0.5rem;
}

.margegender {
	font-size: 15px;
	padding: 1px;
}

.margegender.ios {
	padding-left: 0;
}

.gender ion-item.pad-end {
	--inner-padding-end: 0;
	padding-bottom: 0;
}

.margegender ion-item span {
	font-size: 14px;
}

.padbtm-infos ion-item {
	padding-bottom: 0.2rem;
	--inner-padding-end: 2px !important;
}

.padbtm-infos span,
a {
	font-size: 14px;
}

.briefcase-txt span {
	font-size: 16px;
}

.padbtm-infos ion-icon {
	margin-top: 4px;
	margin-bottom: 4px;
	margin-right: 0.5rem;
	font-size: 15px;
	color: var(--ion-color-medium);
	padding: 1px;
}

.wrap-infos ion-item span {
	white-space: nowrap !important;
	text-overflow: ellipsis;
	overflow: hidden;
	text-decoration: none;
}

.city formly-group {
	margin-left: 8px;
}

.ctn-customer-top formly-group .ctn-entete formly-group .ctn-infos formly-group .city formly-group {
	margin-left: 20px;
}

.contact-infos {
	ion-item {
		--inner-padding-end: 2px !important;

		.marg-infos {
			max-width: 100%;

			span {
				white-space: nowrap !important;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}
	}
}

.capitalize {
	text-transform: capitalize;

	span {
		text-transform: lowercase;
	}

	span::first-letter {
		text-transform: capitalize;
	}
}

.marge-name ion-item .marg-infos span {
	font-family: "New Hero Bold";
	font-size: 16px;
}

.italic {
	font-style: italic;
}

.content-md {
	color: #535353;
}

.item-md {
	color: #424242 !important;
}

.swiper-container {
	position: absolute !important;
	width: 100%;
	min-height: 100%;
	margin-bottom: 5rem;
	height: auto !important;
}

.swiper-wrapper {
	height: auto !important;
	min-height: 100% !important;
}

.swiper-slide {
	display: block !important;
}

calao-slidebox calao-conversation-list .wrapper {
	height: 100vh !important; // Permet de fixer la hauteur de la liste des conversations uniquement lorsqu'elle se trouve dans un slidebox. Ce fix est nécessaire pour le bon fonctionnement du virtual scroll.
}

.btn-ctct {
	--border-radius: 0.85rem !important;
	--background: var(--ion-color-osapp-element);
	--padding-start: 14px;
	--padding-end: 10px;
	--padding-top: 10px;
	--padding-bottom: 10px;
	height: 32px !important;

	ion-icon {
		font-size: 18px;
	}

	ion-label {
		font-family: "New Hero ExtraBold";
		font-size: 12px;
	}
}

textarea {
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom: 1px solid gray;
}

textarea.mat-mdc-input-element.cdk-textarea-autosize {
	max-height: 75px !important;
	min-height: 40px !important;
	font-family: "New Hero";
}

/*#endregion Changement de la CSS déjà écrite*/

.title-color {
	color: #1768de;
}

.padtopLink {
	padding-top: 0.5rem;
}

.padding-l {
	width: 100%;
	text-align: left;
}

.pad-infos {
	padding: 1rem 1rem 0 1rem;
}

.input[type="text"],
.input[type="email"],
.input[type="number"],
.input {
	border: none;
	border-bottom: 1px solid #dedede;
	margin-left: 8px !important;
}

.input::placeholder {
	font-style: italic;
}

.marge-r {
	margin-right: 1rem;
}

.marge-t {
	margin-top: 0.8rem;
}

.float-r {
	float: right;
	margin-right: 1rem;
}

.float-l {
	float: left;
	margin-right: 1rem;
}

.min-w {
	min-width: 7rem;
}

.over-date {
	max-width: 50%;
	overflow: hidden;
	text-overflow: ellipsis;
}

.max-over-flow {
	max-width: 20rem;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.maxi-over-flow {
	max-width: 80%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.over-flow {
	width: 20rem;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.marge-name {
	font-weight: bold;
	margin-top: auto;
	margin-bottom: auto;
}

.margenter {
	text-align: center;
	margin-bottom: 0.3rem;
}

.roundavatar {
	border-radius: 50%;
	background: #87c4ff;
	overflow: hidden;
}

.round-etat {
	position: relative;
	border-radius: 50%;
	background: var(--ion-color-primary);
	overflow: hidden;
	width: 40px;
	height: 40px;

	ion-icon {
		color: var(--ion-color-tertiary-contrast);
		width: 60%;
		height: 60%;
		padding: 0.5rem;
	}
}

.marg-etat {
	margin: 4px 0;
}

.img {
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;
}

.img-rounded {
	border-radius: 50%;
	margin-top: 1rem !important;
	margin: 8px;
	width: 8rem !important;
	height: 8rem !important;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;
	background: var(--ion-color-tertiary);
	color: var(--ion-color-tertiary-contrast);
}

.img-thumbnail {
	object-fit: cover;
	min-width: 100%;
	max-height: 22rem;
}

.auto {
	object-fit: cover;
	max-width: none;
	min-height: 100%;
	height: 15rem;
}

.img-rounded ion-img {
	height: 100%;
}

.input {
	width: 98%;
	margin-left: 1%;
}

.margen {
	margin-bottom: 0.3rem;
	text-align: left;
}

.input-file {
	width: 3.6rem;
	height: 3.6rem;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.picture {
	display: flex;
	justify-content: center;
	max-width: 100%;
}

.picture-edit {
	border-radius: 20px 20px 0 0;
	position: relative;
	display: flex;
}

.avatar.auto.img-thumbnail {
	width: 100%;
	height: auto;
}

.wrapper .count {
	background-color: var(--ion-color-tertiary) !important;
	color: var(--ion-color-dark) !important;
	padding-top: 9px !important;
	padding-left: 21px !important;
	margin-left: -20px !important;
	font-size: 12px !important;
	font-weight: bold;
}

trade-invoicing calao-avatars .wrapper .count {
	background-color: lightgrey !important;
}

.marge-icon {
	display: flex;
	justify-content: center;
}

.center {
	width: 98%;
	text-align: center;
}

.txt-center {
	text-align: center;
}

.gradient {
	background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff 85%);
}

.height50pc {
	height: 50%;
}

.add-participant {
	ion-icon {
		font-size: 24px !important;
	}
}

/*#region Boutons Globaux ------------------------------- */
.calao-btn {
	background-color: var(--ion-color-primary);
	color: #fff;
	border: 0;
	border-radius: 2px;
}

/*... Boutons icône */
.calao-btn.calao-btn-red {
	background-color: #fff;
	font-size: 1rem;
	color: #ff4040;
}

/*... Bouton transparent */
.calao-btn.calao-btn-transparent {
	background-color: transparent;
	height: 100%;
	border: 0;
	color: inherit;
}

.calao-btn.calao-btn-transparent:hover,
.calao-btn.calao-btn-transparent:focus,
.calao-btn.calao-btn-transparent:active {
	background-color: #eee;
}

/*... Bouton rouge hover */
.calao-btn.calao-btn-red-hover:hover,
.calao-btn.calao-btn-red-hover:focus,
.calao-btn.calao-btn-red-hover:active {
	color: #ff4040;
	background: none;
}

/*#endregion Boutons Globaux ------------------------------ */

/*#region Texte ------------------------------------------- */
.txt-label {
	font-size: var(--custom-size-12px);
	color: var(--custom-appcolors-light);
	min-width: 5rem;
}

/*#endregion Texte ---------------------------------------- */

/*#region Backgrounds ------------------------------------- */
/*... Background */
.bg-dark-green {
	background-color: #72ac3a;
}

.bg-purple {
	background-color: #a386df;
}

.bg-light-red {
	background-color: #df9a86;
}

.bg-orange {
	background-color: #e6b768;
}

.bg-sky-blue {
	background-color: #8cdbe1;
}

.bg-yellow {
	background-color: #e3ce41;
}

/*#endregion Backgrounds ---------------------------------- */

/*#region Tableau ----------------------------------------- */
.table.table-striped {
	tr:nth-of-type(even) {
		background: #fcfcfc;
	}

	tr:nth-of-type(odd) {
		background: #eaeaea;
	}

	td {
		padding: 0 10px;
	}
}

/*#endregion Tableau -------------------------------------- */

/*#region Message écran vide ------------------------------ */
.empty-screen {
	display: flex;
	flex: 1;
	padding-top: 3rem;
	align-items: center;
	justify-content: center;
	font-size: var(--custom-size-16px);

	.icon {
		margin: 0 0.5rem 0 0;
	}

	.txt-message {
		padding: 0.5rem 0.75rem;
		border-radius: 3px;
		display: flex;
		color: var(--ion-color-medium);
		font-size: 14px;
		font-family: "New Hero";
		align-items: center;

		ion-icon {
			margin-right: 8px;
		}
	}
}

mat-expansion-panel {
	transmissions-list {
		.empty-screen {
			padding: 0 !important;
		}
	}

	.marg-container {
		margin: 0;
	}
}

mat-expansion-panel {
	.surveillances-list,
	transmissions-list,
	.facturation-list {
		.empty-screen {
			padding: 0.5rem 0;
			border-radius: 3px;
			display: flex;
			color: var(--ion-color-medium);
			font-size: 14px;
			font-family: "New Hero";
			align-items: start;
			justify-content: start;

			.txt-message {
				padding: 0.5rem 0;
			}
		}
	}
}

mat-expansion-panel .surveillance-area .surveillances-list {
	margin: 0;
}

/*#endregion Message écran vide --------------------------- */

/*#region Formulaires ------------------------------------- */

.place-label {
	formly-group {
		flex-direction: row !important;
	}

	.margelect {
		margin: 0 !important;
	}
}

/*#region Input, Select ------------------------------------*/
input,
.input,
select {
	border: 1px solid #ccc;
	border-radius: 2px;
	text-align: left;
	padding: 0 1rem;
}

/*#endregion Input, Select ---------------------------------*/

/*#region CheckBox ---------------------------------------- */
.checkbox-icon {
	position: relative;
	width: 28px;
	height: 28px;
	display: block;
	border: 0;
	background: transparent;
	cursor: pointer;
	-webkit-appearance: none;
	padding: 2px;
}

input:checked + .checkbox-icon:before {
	border-width: 2px;
}

input:checked + .checkbox-icon:after {
	opacity: 1;
}

/*#endregion CheckBox ------------------------------------- */

.action-button-text {
	font-size: 10px;
	color: #505050;
	word-break: normal;
	margin: 0;
}

/*#endregion Formulaires ---------------------------------- */

.item {
	--min-height: 24px;
	--max-height: 24px;

	ion-label {
		margin-top: 0;
		margin-bottom: 0;
	}
}

mat-form-field ion-item {
	--min-height: 40px !important;
	--max-height: 40px !important;
}

ion-button {
	text-transform: initial;
	--ripple-color: transparent;
	--background-activated: transparent;
	--background-focused: transparent;
	--background-hover: transparent;
	font-size: 14px;
}

ion-item-options ion-button {
	--padding-start: 16px;
	--padding-end: 16px;
}

ion-button.ios.btn-more {
	--padding-end: 0;
}

.no-padding {
	padding: 0;
}

formly-field.align-action-btn readonly-wrapper ng-component {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.modal-acte-manager-validate-alert .alert-head {
	padding: 0;
}

.width100pc formly-group formly-field .button-has-icon-only,
calao-groups-checklist .button-small,
formly-field-permission-roles-select ion-chip,
ion-list .button-solid,
.file-picker-button,
.licamera-button,
.add-btn {
	--background: var(--ion-color-primary) !important;
	background: var(--ion-color-primary) !important;
	--border-radius: 50px;
	border-radius: 50px;
	font-family: "New Hero Bold";
	font-size: 14px;

	ion-label {
		font-family: "New Hero Bold" !important;
		color: var(--ion-color-primary-contrast, white) !important;
	}
}

.width100pc formly-group formly-field div .button-has-icon-only.button-solid,
.file-picker-button,
.licamera-button {
	height: 50px;
	width: 50px;
	--padding-start: 0 !important;
	--padding-end: 0 !important;
}

/* Alerte encaissement customer */
.invoice-popup {
	.alert-wrapper {
		background: var(--ion-color-background);
		border-radius: 20px;

		.alert-head h2 {
			color: var(--ion-color-primary);
			font-weight: bold;
		}

		.alert-message {
			font-size: 14px;
			padding-bottom: 8px;
		}

		.alert-radio-group {
			border: none;
			margin: 0 10px;
			box-shadow: var(--calao-shadow);

			button {
				background: white;
				border-bottom: 1px solid var(--ion-color-light-shade);
				height: 49px;

				.alert-radio-label {
					font-size: 14px;
				}
			}
		}

		.cancel-btn {
			background: var(--ion-color-light);
			color: var(--ion-color-danger);
			border-radius: 20px;
			padding: 10px 16px;
		}

		.validate-btn {
			background: var(--ion-color-success);
			color: white;
			border-radius: 20px;
			padding: 10px 16px;
			box-shadow: var(--calao-shadow);
		}
	}
}

ion-alert {
	.alert-wrapper {
		border-radius: 14px !important;
		padding: 8px;
	}

	.alert-title {
		font-family: "New Hero Bold";
		font-size: 18px !important;
		color: var(--ion-color-dark) !important;
		text-align: center;
	}

	.alert-message {
		font-size: 14px !important;
		color: var(--ion-color-dark) !important;
	}

	.alert-button {
		text-transform: initial !important;
		color: var(--ion-color-medium) !important;
		padding: 10px 20px !important;
	}

	.validate-btn {
		font-family: "New Hero Bold";
		background: var(--ion-color-primary) !important;
		color: var(--ion-color-primary-contrast) !important;
		border-radius: 50px !important;
	}

	.cancel-btn {
		font-family: "New Hero Bold";
		background: var(--ion-color-primary) !important;
		color: var(--ion-color-primary-contrast) !important;
		border-radius: 50px !important;
	}
}

.roles-button,
.end-btn ion-button {
	height: 40px !important;
	--border-radius: 40px !important;
}

/* Couleurs des entités */
trade-contact .picture-edit {
	background: var(--ion-color-contact-icon) !important;
}

trade-group .picture-edit {
	background: var(--ion-color-group-icon) !important;
}

site-details .picture-edit {
	background: var(--ion-color-site-icon) !important;
}

mat-form-field .mdc-evolution-chip-set__chips {
	margin: 4px 0;
}

calao-formly-wrapper .picture-edit {
	background: var(--ion-color-customer-icon) !important;
}

trade-couverture-modal,
trade-facturation-context-modal {
	.sc-ion-modal-md-h {
		--border-radius: 20px;
	}

	.mat-form-field-appearance-outline {
		margin: 2px 0;
		width: 100%;
		max-width: 320px;
		.mat-mdc-form-field-infix {
			padding: unset;
		}
		.mat-mdc-form-field-wrapper {
			padding-bottom: 0;
		}
	}

	input.mat-mdc-input-element {
		height: 40px;
	}
	.mat-select-arrow {
		margin-top: 8px;
	}
}

// Classe utilisé pour décaler la toolbar et qu'elle ne bloque pas la cliquabilité de certains éléments de l'UI.
.push-toolbar {
	margin-bottom: 1.5rem;
}

.pad-edit-contacts {
	padding-left: 0.5rem;
}

.margin-group {
	margin-left: 0.5rem;
	padding-bottom: 0.2rem;
}

.margin-left {
	margin-left: 0.5rem;
	padding-top: 0.6rem;
}

/* Transmissions customers */
ion-slide transmissions-list ion-virtual-scroll ion-item ion-text.text-container {
	padding-left: 0.8rem;
}

ion-slide
	ng-component
	section.list.listEventOccurrences.marge-btm-list-eventOccurrences
	mat-accordion
	mat-expansion-panel
	transmissions-list
	ion-virtual-scroll
	ion-item
	ion-text.text-container {
	padding-left: 0;
}

/* Bandeau des conversations */

@media screen and (max-width: 320px) {
	#frame .content .contact-profile .conv-infos {
		min-width: 50%;
	}

	#frame .content .contact-profile .element-lie {
		width: 50%;
	}
}

/* Marqueur item actif */

.actif {
	height: 10px;
	display: flex;
	align-items: center;
}

ion-item .actif {
	width: 100%;
}

// Marqueur conflit item
.background-warning {
	background-color: var(--ion-color-warning);
}

// Marqueur information item
.background-info {
	background-color: var(--ion-color-osapp-element);
}

//#region super-tabs

super-tab-button {
	display: flex;
	flex-direction: row;
	justify-content: center;

	ion-icon {
		padding-top: 0;
		fill: var(--ion-color-primary);
	}

	ion-label {
		color: var(--ion-color-primary);
		display: flex;
		align-items: center;
		font-size: 14px;
		font-family: "New Hero Bold";
		text-transform: capitalize;
	}
}

super-tabs-toolbar {
	&:after {
		background: transparent;
	}
}

super-tab-button.ion-activatable {
	color: transparent !important;
}

super-tab-button.active {
	--color-hover: var(--ion-color-primary) !important;
	background-color: var(--ion-color-primary) !important;

	ion-label {
		color: var(--ion-color-primary-contrast) !important;
	}
}

super-tabs-toolbar {
	margin: 0 10px 0 10px !important;
	width: unset !important;
	height: 3.4rem;
	background: transparent;
	--st-indicator-color: transparent;
	--st-indicator-height: 6px;

	super-tab-button:first-of-type {
		border-top-left-radius: 29px !important;
		border-bottom-left-radius: 29px !important;
		border: 1px solid var(--ion-color-primary) !important;
	}

	super-tab-button:last-of-type {
		border-top-right-radius: 29px !important;
		border-bottom-right-radius: 29px !important;
		border: 1px solid var(--ion-color-primary) !important;
	}

	super-tab-button {
		height: 3rem;
		background: var(--ion-color-background) !important;
		margin-top: 10px;
		border-top: 1px solid var(--ion-color-primary) !important;
		border-bottom: 1px solid var(--ion-color-primary) !important;
	}
}

//#endregion super-tabs

.slidebox-nav {
	background-color: transparent !important;
	padding-top: 10px;
	margin-bottom: -1px;
}

.slidebox-nav::-webkit-scrollbar {
	display: none;
}

// Onglet fiche customer et event
readonly-wrapper ng-component calao-slidebox .slidebox .slidebox-nav,
.title + calao-slidebox .slidebox .slidebox-nav {
	.ion-color-slidebox-button {
		--ion-color-base: white !important;
		--ion-color-contrast: var(--ion-color-osapp-element);
		--border-radius: 14px !important;
		--box-shadow: var(--calao-shadow) !important;
		height: 40px;
		margin-bottom: 5px;
	}

	.ion-color-slidebox-button.slidebox-active-tab {
		--border-radius: 14px 14px 0 0 !important;
		height: 45px;
		margin-bottom: 0;
	}
}

@media screen and (min-width: 780px) {
	.slidebox-nav {
		overflow-x: hidden !important;
	}
}

readonly-wrapper ng-component calao-slidebox .slidebox .slidebox-nav + ion-slides,
.title + calao-slidebox .slidebox ion-slides {
	background: var(--ion-color-sections-container);
}

.border-l.slidebox-active-tab {
	z-index: 1;
}

.border-l.slidebox-active-tab {
	border-bottom: none !important;
}

.slider-button .button-native {
	background-color: var(--ion-color-background) !important;
}

super-tabs-container {
	max-width: 100%;
	max-height: 100%;

	super-tab {
		max-width: 100%;
		max-height: 100%;
		width: calc(100% - 20px);
		margin: 10px;

		ion-content {
			max-width: 100%;
			max-height: 100%;
		}
	}

	.hidden {
		width: 0 !important;
	}
}

trade-facturation ion-toolbar {
	margin-bottom: 0 !important;
}

ion-header .sc-ion-buttons-md-s .button-has-icon-only.button-clear {
	--padding-start: 0 !important;
	--padding-end: 0 !important;
}

.links ion-icon,
.links ion-icon + ion-note {
	color: var(--ion-color-primary) !important;
}

.header-md::after {
	display: none;
}

.header-ios ion-toolbar:last-of-type {
	--border-width: 0 0 0px !important;
}

indemnites cdk-virtual-scroll-viewport::-webkit-scrollbar {
	width: 0;
}

.eventOccurrence-price avatar img {
	width: 28px !important;
	min-width: 28px !important;
	height: 28px !important;
}

/* Optimisations browser */

@media screen and (min-width: 600px) {
	/* Accueil */
	.marg-tuiles {
		margin: 0 6%;
	}
}

@media screen and (min-width: 992px) {
	/* Accueil */
	.marg-tuiles {
		margin: 0 12%;
	}

	/* Listes */
	transmissions-list .transmissions-list {
		padding: 0;
	}

	.transmissions-list {
		padding: 0 12%;
	}

	super-tab ion-content {
		--padding-start: 12%;
		--padding-end: 12%;
	}

	ion-slide {
		padding: 0 12%;
	}

	trade-contact .form-content,
	calao-form .form-content {
		padding: 18px 12% !important;
	}

	/* Slides event */
	calao-formly-wrapper .form-content {
		padding: 18px calc(12% + 10px) !important;
	}

	.ctnEnteteDate {
		margin-top: 10px;
	}

	/* Businesses et contacts */
	.ctn-entete {
		width: 60%;
		margin: auto;

		.ctn-infos.pos-rel.dis-line.height100pc.width50pc {
			margin: 10px 20px;
		}
	}

	.ctn-infos.pos-rel.dis-line.height100pc.width50pc {
		border-radius: 8px;
		box-shadow: var(--calao-shadow);
		padding: 10px;
	}

	.row-bottom {
		padding-bottom: 1rem;
	}

	trade-contact .row-bottom {
		padding-bottom: 0;
	}

	.header-transmission {
		padding: 0 12%;
	}

	ion-slide trade-conversations-list .marg-browser {
		padding: 0;
	}
}

@media screen and (min-width: 760px) {
	ion-modal::part(content) {
		width: 600px !important;
		height: 80% !important;
		--border-radius: 14px;
	}

	ion-modal.transparent {
		--background: none !important;
		--box-shadow: none !important;
	}
}

//Affichage des businesses
trade-business-item {
	ion-item {
		margin: 1px 0 1px 0px;
		width: 100%;
		border-radius: 10px;

		calao-list-item-content {
			background: var(--ion-color-contact-item-background);
		}

		.item-title,
		.city ion-note {
			width: 100%;
			display: -webkit-box !important;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 1;
			overflow: hidden;
		}
	}
}

calao-filter-bar-item-selector-field {
	margin: 8px 0;

	osapp-selector {
		margin-left: 8px !important;
	}
}

//Affichage des onglets spécifique aux businesses
calao-entity calao-tabs {
	background: var(--ion-color-primary-contrast);

	calao-tabs-tab .search-area,
	calao-doc-explorer-slide .content-wrapper,
	.form-content {
		padding: 0 !important;
		.mat-form-field-appearance-outline .mat-mdc-form-field-wrapper {
			margin: 0 !important;
		}
	}

	calao-tabs-segments,
	calao-scroll-tabs-segments {
		border-bottom: 1px solid var(--ion-color-light-shade);
		width: 100%;
		max-width: 100%;

		ion-segment {
			background: var(--ion-color-primary-contrast);
			margin: auto !important;
			overflow-x: auto;
			border-radius: 0 !important;
			border: none !important;
			height: 48px !important;

			ion-segment-button {
				border-right: 0 !important;
				min-width: auto !important;
				--padding-start: 2px !important;
				--padding-end: 2px !important;

				ion-label {
					margin-top: -2px !important;
					font-size: 10px !important;
				}

				ion-icon,
				ion-label {
					opacity: 0.6;
				}
			}

			ion-segment-button.segment-button-checked,
			ion-segment-button .segment-button-checked:hover {
				--indicator-color: var(--ion-color-primary) !important;

				background-color: var(--ion-color-primary-contrast) !important;

				ion-label,
				ion-icon {
					color: var(--ion-color-primary) !important;
					opacity: 1;
				}
			}
		}
	}

	calao-tabs-segments {
		ion-segment {
			width: 100% !important;
			max-width: 620px;
		}
	}

	calao-scroll-tabs-segments {
		display: flex;
		justify-content: center;

		ion-segment {
			width: fit-content !important;
		}

		calao-tabs-segment {
			width: 80px;
		}
	}

	.end-btn,
	ion-list {
		background: var(--ion-color-primary-contrast) !important;
	}

	calao-conversation-list div.marg-container {
		margin: 0;
		padding-top: 10px;
	}
}

ion-segment-button {
	border-radius: unset;
}

ion-segment-button.segment-button-checked,
ion-segment-button .segment-button-checked:hover {
	--indicator-color: transparent !important;
}

/* CSS pour le mode browser du plugin cordova-plugin-camera */

.cordova-camera-capture {
	display: inline-grid;
	width: 100%;

	video {
		width: fit-content;
		height: auto;
		margin-left: auto;
		margin-right: auto;
	}

	button {
		margin-left: 40%;
		margin-right: 40%;
		background: var(--ion-color-primary, #3880ff);
		color: var(--ion-color-primary-contrast, #fff);
		height: 48px;
		border-radius: 8px;
		font-size: 16px;
	}
}

/* CSS types groupes */
.group-type .mdc-evolution-chip-set__chips {
	margin: 0.5rem 0.2rem 1rem !important;
}

calao-groups-checklist {
	.tags-button {
		margin-bottom: 10px !important;
	}

	.group-list-body {
		display: flex;
		flex-direction: column;
		width: 100%;

		ion-item {
			--max-height: 36px !important;
			--min-height: 36px !important;
			font-size: 14px !important;
		}
	}

	.divider {
		background-color: var(--ion-color-sections-container);
		height: 1px;
		flex-grow: 1;
		margin-left: 10px;
		margin-top: 6px;
		width: 76%;
	}
}

/* Affichage sélecteur dans les tournées */
.border-filter osapp-selector {
	drag-scroll {
		margin-top: 4px;
		height: 45px;
	}

	.left-button {
		background: linear-gradient(
			270deg,
			rgba(255, 255, 255, 0) 0%,
			rgba(255, 255, 255, 0.948327850877193) 35%,
			rgba(255, 255, 255, 1) 70%
		);
		left: 0;
		color: var(--ion-color-osapp-element);
	}

	.right-button {
		background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 0) 0%,
			rgba(255, 255, 255, 0.948327850877193) 35%,
			rgba(255, 255, 255, 1) 70%
		);
		right: 0;
		color: var(--ion-color-osapp-element);
	}
}

/* Page de diagnostics */
calao-diagnostic-ai > ion-list,
calao-diagnostic > ion-list {
	ion-item {
		--min-height: 42px !important;
		--max-height: 42px !important;
	}
}

/* Hauteur du cdk virtual scroll */
cdk-virtual-scroll-viewport {
	background-color: white;

	ion-item-sliding {
		ion-item {
			--min-height: 70px !important;
			--max-height: 70px !important;
			height: 70px;
			background-color: white;
		}
	}
}

calao-conversation-list cdk-virtual-scroll-viewport {
	border-radius: 20px;
}

transmissions-list cdk-virtual-scroll-viewport ion-item-sliding ion-item {
	background-color: white;
}

/* Optimisations side menu */
ion-toolbar.toolbar-menu {
	--min-height: var(--calao-toolbar-height, 48px);
	--max-height: var(--calao-toolbar-height, 48px);

	ion-button {
		pointer-events: none;
	}

	ion-icon {
		font-size: 34px;
	}

	ion-title {
		padding-left: 4px;
		font-weight: bold;
	}
}

.side-menu-with-header,
.side-menu-without-header {
	height: 100%;
}

.user-part {
	z-index: 10;

	ion-label {
		text-transform: none !important;
		color: var(--ion-color-dark) !important;
		font-size: 14px;
	}

	ion-item-divider ion-icon {
		color: var(--ion-color-dark) !important;
	}

	.standard-link {
		--background: var(--ion-color-sections-container-tint);
		background: var(--ion-color-sections-container-tint);

		ion-label {
			font-size: 14px !important;
			margin: 8px;
			color: var(--ion-color-dark) !important;
		}
	}
}

.menu-part calao-link ion-item {
	--min-height: 32px;
	padding-top: 0;
}

.user-part calao-link ion-item {
	--min-height: 42px;
	padding-top: 0;
}

@media screen and (min-width: 992px) {
	ion-menu {
		--max-width: 280px;
		--min-width: 280px;
		--width: 280px;
	}
}

/* Affichage tag états et date des séances **/
.customer-tag {
	ion-icon {
		font-size: 14px;
	}

	avatar {
		ion-icon.avatar-icon {
			font-size: 14px !important;
			width: 14px !important;
			height: 14px !important;
			padding: 4px 4px 4px 8px;
		}
	}
}

/* Affichage des miniatures de la galerie d'ordonnances **/
mat-expansion-panel trade-ordonnances-gallery .gallery-content {
	width: 60px;
	height: 60px;

	.file-icon {
		font-size: 60px;
		color: gray;
	}

	.ctn-apercu {
		max-height: 60px;
		max-width: 60px;
	}
}

/* Optimisations side menu petit device */
@media screen and (max-width: 360px) {
	.user-part {
		ion-item-divider {
			height: 48px !important;

			ion-avatar {
				width: 30px !important;
				height: 30px !important;
			}
		}
	}
}

.line,
.divider {
	background-color: var(--ion-color-sections-container);
	height: 1px;
	flex-grow: 1;
	margin-left: 10px;
}

.ion-no-padding {
	padding: 0 !important;
}

/* Sélecteurs au format tag de la liste des clients */
.customers-list-tags-lists-wrapper .tags-list-label {
	display: flex;
	flex-direction: row;
	padding-left: 12px;
	padding-bottom: 3px;
}

//#region calao-tab

ion-button,
calao-tab {
	ion-badge {
		position: absolute;
		font-size: 9px !important;
		font-family: "New Hero Bold";
		padding: 4.5px 0;
		text-align: center;
		right: 8px;
		width: 16px;
		height: 16px;
		top: -6px;
	}
}

calao-dynamicpage ion-button,
calao-tab {
	ion-badge.ios {
		right: 0px !important;
		top: 9px !important;
	}
}

calao-tab ion-badge {
	left: 16px !important;
	top: 12px !important;
}

@media screen and (max-width: 992px) {
	calao-tab ion-badge {
		left: 41px !important;
		top: 3px !important;
	}
}

//#endregion calao-tab

/* Dashboard et listes */
calao-list-item-content .content-wrapper {
	span,
	.achievement,
	.achievement-done {
		font-family: "New Hero";
		font-size: 14px;
		width: 100%;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
		overflow: hidden;
		color: var(--ion-color-dark);
	}

	ion-note span {
		font-family: "New Hero UltraLigth Italic";
	}
}

calao-list-item-start {
	ion-icon,
	span {
		color: var(--ion-color-dark);
	}

	ion-icon {
		margin-bottom: 2px;
	}
}

calao-dashboard-tile.task,
calao-calendar-event.task,
div.task {
	ion-icon,
	ion-icon.event-icon {
		color: var(--ion-color-task);
	}

	ion-icon.done-icon {
		color: var(--ion-color-done-task);
	}

	ion-icon.late-icon {
		color: var(--ion-color-late-task);
	}

	ion-icon.title-icon {
		color: var(--ion-color-dark);
	}
}

calao-dashboard-tile.event,
calao-calendar-event.event,
div.event {
	ion-icon,
	ion-icon.event-icon {
		color: var(--ion-color-event);
	}

	ion-icon.title-icon {
		color: var(--ion-color-dark);
	}
}

.cal-all-day-events {
	margin-right: 8px;

	mwl-calendar-week-view-event {
		.cal-event-light {
			max-height: 31px !important;
			width: 100% !important;
			-webkit-box-orient: vertical !important;
			-webkit-line-clamp: 1 !important;
			overflow: hidden !important;
			padding: 6px 4px !important;
		}
	}
}

trade-documents calao-document-item {
	.content-wrapper,
	.detail-wrapper {
		background: var(--ion-color-primary-contrast) !important;
	}
}

calao-doc-picker,
calao-entity-picker-modal {
	.virtual-scroll-list {
		background: var(--ion-color-primary-contrast) !important;
	}
}

calao-event-item calao-list-item {
	border-radius: 14px !important;
}

trade-tasks-list {
	padding: 5px 10px;
	background: var(--ion-color-background);
}

.selector-container {
	padding: 10px;
	background: var(--ion-color-background);
}

calao-conversation {
	#frame {
		background: var(--ion-color-background) !important;

		.content {
			.messages {
				--background: var(--ion-color-background) !important;
			}
		}

		.contact-profile {
			background: var(--ion-color-background) !important;
			border-bottom: 1px solid var(--ion-color-background);

			.conv-infos p {
				font-family: "New Hero";
			}
		}
	}

	#sidepanel {
		background: var(--ion-color-background) !important;
	}

	#contacts {
		background: var(--ion-color-background) !important;
		border-top: solid 1px var(--ion-color-background) !important;
		margin-top: -1px !important;
	}
}

ion-list.virtual-scroll-list {
	background: var(--ion-color-background);
}

//#region calao-calendar

calao-calendar ion-segment ion-segment-button {
	min-width: 45px !important;
}

.calendar-full-height trade-todo-item-inline {
	margin: 0 !important;
}

calao-calendar mwl-calendar-month-view .cal-days,
calao-calendar mwl-calendar-week-view .cal-time-events,
calao-calendar trade-day-list-view calao-virtual-scroll .cdk-virtual-scroll-viewport {
	touch-action: pan-y !important;
}

//#endregion calao-calendar

//#region calao-database-sync-animated-icon

calao-database-sync-animated-icon {
	.toolbar-icon {
		width: 19px !important;
	}
}

//#endregion calao-database-sync-animated-icon

//#region blank-information.component

.blank-information {
	margin: auto !important;

	.content {
		filter: opacity(100%) !important;

		.title-icon {
			.icon-content {
				background-color: var(--ion-color-primary-contrast) !important;
				width: 100px !important;
				height: 100px !important;

				ion-icon {
					font-size: 40px !important;
					margin: 30px 0 0 0 !important;
				}
			}
		}

		.title,
		.subtitle {
			color: var(--ion-color-dark) !important;
		}

		.title {
			font-family: "New Hero Bold" !important;
		}

		.subtitle {
			margin-top: 10px;
		}
	}
}

//#endregion blank-information.component.blank-information

//#region trade-contacts-list
calao-contacts-list ion-avatar,
calao-groups-list ion-avatar,
trade-businesses-list ion-avatar {
	ion-icon,
	img {
		color: var(--ion-color-dark);
		background-color: var(--ion-color-background);
		border-radius: 50%;
		pointer-events: none;
	}
}

trade-businesses-list ion-avatar {
	ion-icon,
	img {
		border-radius: 10%;
	}
}

calao-virtual-scroll ion-item-sliding,
calao-virtual-scroll ion-item.first-contact {
	margin: 1px 0 1px 0 !important;
	width: 100% !important;
	border-radius: 14px !important;
}

//#endregion trade-contacts-list

//#region calao-contacts-filter-bar

@media screen and (min-width: 992px) {
	calao-contacts-filter-bar calao-filter-bar,
	calao-groups-filter-bar calao-filter-bar {
		padding: 0 12% !important;
	}
}

//#region calao-filter-bar-search-input
trade-contacts,
trade-groups,
ion-modal {
	calao-filter-bar-search-input {
		margin: 0 !important;
	}
}

trade-conversations-list {
	.search-area {
		padding: 0 !important;
	}

	.marg-container {
		margin: 0 !important;
	}

	.calao-virtual-scroll-wrapper {
		background: transparent !important;
	}

	ion-item {
		--border-color: transparent;
	}
}

calao-doc-explorer .calao-virtual-scroll-wrapper {
	background: transparent !important;
}

//#endregion calao-filter-bar-search-input

//#region contact-selector

#contact-selector-modal {
	--padding-start: 0px !important;
	--padding-end: 0px !important;
	--padding-bottom: 0px !important;
}

contact-selector-modal {
	ion-content {
		--padding-top: 0px !important;
		--padding-bottom: 0px !important;
		--padding-start: 0px !important;
		--padding-end: 0px !important;

		.slide-wrapper {
			background-color: var(--ion-color-primary-contrast);

			.selector-list {
				.virtual-scroll-list {
					background-color: var(--ion-color-primary-contrast);
				}
			}
		}

		.item {
			height: initial !important;
			--min-height: 70px !important;
			--max-height: 70px !important;
		}
	}

	.empty-container {
		margin-top: 0 !important;
	}

	.marg-container {
		margin: 0 !important;
	}

	ion-item.user-contact,
	ion-virtual-scroll {
		margin: 0 !important;
		border-radius: 0px !important;
		box-shadow: none !important;
	}

	cdk-virtual-scroll-viewport {
		box-shadow: none !important;
	}
}

calao-tabs calao-tabs-tab-container {
	padding-top: 0 !important;
}

calao-tabs-tab .search-box {
	padding: 0 !important;
}

//#endregion contact-selector

//#region bouton de visio
ion-button.visio-icon,
.title-visio-button ion-button {
	--padding-start: 16px;
	--padding-end: 16px;
	margin-left: 0;
	width: 194px !important;

	ion-label {
		font-size: 14px;
		font-family: "New Hero Bold";
	}

	ion-icon {
		color: var(--ion-color-primary-contrast) !important;
	}
}

.header .contact-profile .title-visio-button .conv-infos {
	padding-left: 0 !important;

	p {
		margin-left: 0 !important;
		width: 100% !important;
		max-width: 600px !important;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2 !important;
		overflow: hidden;
	}
}

//#endregion bouton visio

//#region authentification
.selection-button-container {
	ion-icon {
		color: black !important;
	}

	ion-button span,
	ion-label span {
		color: var(--ion-color-primary);
		font-weight: normal !important;
	}
}

.logo-size {
	width: 200px !important;
	height: auto !important;
}

//#endregion authentification

//#region calao-doc-explorer
calao-doc-explorer {
	ion-grid {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
}

//#endregion calao-doc-explorer

.list-view-todo-item trade-todo-item-inline {
	margin: 0;
}

//#region calao-date-range-picker

.mat-date-range-input-wrapper {
	max-width: 100% !important;
}

mat-date-range-input.placeholder {
	.mat-date-range-input-start-wrapper {
		max-width: 100% !important;
	}

	.mat-date-range-input-end-wrapper {
		width: 0px !important;
	}
}

.mat-mdc-form-field-flex {
	align-items: center !important;
}

//#endregion calao-date-range-picker

//#region modale optimisation
event-optimization-input-modal {
	.date-wrapper {
		ion-item,
		ion-label {
			--padding-start: 0 !important;
			padding: 0 !important;
			font-family: "New Hero Bold" !important;
		}
	}
}

ion-modal.modal-rounded::part(content) {
	--border-radius: 14px;
}

//#endregion modale optimisation
//#region calao-list-item-content-title

.item-title {
	width: 100%;
	display: flex;

	ion-label,
	span {
		font-family: "New Hero Bold" !important;
		font-size: 16px !important;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
		overflow: hidden !important;
		color: var(--ion-color-dark) !important;
	}

	ion-icon {
		font-size: 12px;
		margin-right: 4px;
	}
}

span.item-title {
	font-family: "New Hero Bold" !important;
	font-size: 16px !important;
	display: flex !important;
}

//#endregion calao-list-item-content-title

//#region ion-item-option

ion-item-options {
	ion-item-option {
		padding: 8px !important;

		ion-icon {
			font-size: 20px !important;
		}

		ion-label {
			padding-left: 6px !important;
			font-family: "New Hero Bold" !important;
			text-transform: initial !important;
		}
	}
}

//#endregion buttonOption

//#region calao-reminder-field

.notifications {
	display: flex;
	justify-content: center;
	width: 100%;
}

.readonly-container {
	ion-icon.notification {
		font-size: 22px;
		color: var(--ion-color-notification) !important;
	}
}

//#endregion calao-reminder-field

//#region trade-reminder-occurrence-edit

trade-reminder-occurrence-edit calao-formly-wrapper calao-checkbox-field ion-icon {
	margin-right: 5px;
}

//#endregion trade-reminder-occurrence-edit

//#region popover
ion-popover {
	--min-width: 270px;
	--background: transparent;

	ion-item {
		--padding-top: 8px !important;
		--padding-bottom: 8px !important;

		ion-label {
			width: 100%;
			display: -webkit-box !important;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 1;
			overflow: hidden !important;
		}
	}
}

//#endregion popover

//#region calao-roles-selector

calao-roles-selector.padding-10 {
	.tags-list-container {
		padding: 10px !important;
	}
}

//#endregion calao-roles-selector

//#region calao-list-item-content-suffix

calao-list-item-content-suffix.dark-bold {
	ion-note.ion-text-wrap {
		font-family: "New Hero Bold";
		color: var(--ion-color-dark) !important;
		font-style: normal !important;
	}
}

//#endregion calao-list-item-content-suffix

calao-entity-modal {
	calao-sub-header {
		ion-toolbar {
			height: 56px !important;
		}
	}
}

//#region calao-add-document-modal

calao-add-document-modal ion-item {
	--background: transparent !important;

	gallery .ctn-liste-gallery {
		background: transparent !important;
	}
}

//#endregion calao-add-document-modal

//#region calao-filter-bar-item-reset

calao-filter-bar-item-reset {
	.label {
		font-family: "New Hero" !important;
		font-size: 14px !important;
		color: var(--ion-color-medium) !important;
		text-decoration: underline !important;
		text-transform: capitalize !important;
	}
}

//#endregion calao-filter-bar-item-reset

.week-view-event-container {
	container-type: inline-size;
	container-name: week-view-event-container;
	height: -webkit-fill-available;
}

@container week-view-event-container (max-width: 25px) {
	calao-calendar-event-light {
		ion-label.title {
			display: none !important;
		}
		.task {
			width: 10px !important;
			height: 100% !important;
			background-color: var(--ion-color-task) !important;
			border-color: var(--ion-color-task) !important;
		}
		.event {
			width: 10px !important;
			height: 100% !important;
			background-color: var(--ion-color-event) !important;
			border-color: var(--ion-color-event) !important;
		}
		.full-day {
			width: 10px !important;
			height: 31px !important;
		}
	}
}

.cal-week-view .cal-time-events .cal-event-light {
	width: calc(100% - 2px) !important;
	height: calc(100% - 2px) !important;
	margin: 1px !important;
	padding: 6px 4px !important;
	line-height: 16px !important;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: normal;

	ion-label {
		margin-left: 0px !important;
	}
}

//#region ContactsPickerModalComponent

calao-contacts-picker-modal {
	.search-area {
		padding: 0px !important;
	}

	.selector-list {
		.checkbox-all {
			justify-content: flex-end !important;
		}
	}

	osapp-selector drag-scroll {
		margin-right: 0px !important;
	}

	.mat-badge-content {
		width: 16px !important;
		height: 16px !important;
		display: flex !important;
		justify-content: center !important;
		align-items: center !important;
		margin-left: 5px !important;
		font-size: 12px !important;
		background-color: var(--ion-color-dark) !important;
		top: 8px !important;
	}
}

//#endregion ContactsPickerModalComponent

//#region calao-recurrences-field-modal-content

calao-recurrences-field-modal-content .periodicity osapp-date-time {
	ion-item {
		display: flex;

		ion-label {
			--color: var(--ion-color-dark) !important;
			font-family: "New Hero Bold" !important;
		}
	}
}

//#endregion calao-recurrences-field-modal-content

//#region calao-event-duration-field

formly-field.event-duration {
	.mat-mdc-form-field-infix {
		padding-bottom: 0 !important;
	}
}

//#endregion calao-event-duration-field

//#region calao-groups-field-modal

calao-groups-field-modal .virtual-scroll-list {
	background: var(--ion-color-primary-contrast) !important;
}

//#endregion calao-groups-field-modal

//#region calao-menu

calao-menu {
	.full-height, .side, ion-item-group {
		height: 100%;
	}
}

//#endregion calao-menu

@import "./inline-field-layout.scss";
