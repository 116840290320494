// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/v2/theming/
$font-path: "./../assets/fonts";

@import "ionic.globals";

// Ionic Colors
// --------------------------------------------------

:root {
	/** Theme de base a modifier en priorité.
	* Pour generer un theme pour l'application :
	* https://ionicframework.com/docs/theming/color-generator
	*/

	--ion-color-primary: #386aff;
	--ion-color-primary-rgb: 56, 106, 255;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255, 255, 255;
	--ion-color-primary-shade: #315de0;
	--ion-color-primary-tint: #87a5ff;
	--ion-color-primary-transparency: #386aff41;

	--ion-color-secondary: var(--ion-color-primary);
	--ion-color-secondary-rgb: var(--ion-color-primary-rgb);
	--ion-color-secondary-contrast: var(--ion-color-primary-contrast);
	--ion-color-secondary-contrast-rgb: var(--ion-color-primary-contrast-rgb);
	--ion-color-secondary-shade: var(--ion-color-primary-shade);
	--ion-color-secondary-tint: var(--ion-color-primary-tint);

	--ion-color-tertiary: #87a5ff;
	--ion-color-tertiary-rgb: 135, 165, 255;
	--ion-color-tertiary-contrast: var(--ion-color-dark);
	--ion-color-tertiary-contrast-rgb: var(--ion-color-dark-rgb);
	--ion-color-tertiary-shade: #7791e0;
	--ion-color-tertiary-tint: #93aeff;

	--ion-color-success: #18d495;
	--ion-color-success-rgb: 24, 212, 149;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255, 255, 255;
	--ion-color-success-shade: #15bb83;
	--ion-color-success-tint: #f1ffd8;

	--ion-color-warning: #ec8960;
	--ion-color-warning-rgb: 236, 137, 96;
	--ion-color-warning-contrast: var(--ion-color-dark);
	--ion-color-warning-contrast-rgb: var(--ion-color-dark-rgb);
	--ion-color-warning-shade: #d07954;
	--ion-color-warning-tint: #fff0d8;

	--ion-color-danger: #f64445;
	--ion-color-danger-rgb: 246, 68, 69;
	--ion-color-danger-contrast: var(--ion-color-primary-contrast);
	--ion-color-danger-contrast-rgb: var(--ion-color-primary-contrast-rgb);
	--ion-color-danger-shade: #d83c3d;
	--ion-color-danger-tint: #fdd9da;

	--ion-color-alert: #ffcd38;
	--ion-color-alert-rgb: 255, 205, 56;
	--ion-color-alert-contrast: var(--ion-color-dark);
	--ion-color-alert-contrast-rgb: var(--ion-color-dark-rgb);
	--ion-color-alert-shade: #e0b431;
	--ion-color-alert-tint: #fff8e2;

	--ion-color-dark: #1e2331;
	--ion-color-dark-rgb: 30, 35, 49;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255, 255, 255;
	--ion-color-dark-shade: #1a1f2b;
	--ion-color-dark-tint: #353946;

	--ion-color-medium: #8c8f98;
	--ion-color-medium-rgb: 140, 143, 152;
	--ion-color-medium-contrast: var(--ion-color-dark);
	--ion-color-medium-contrast-rgb: var(--ion-color-dark-rgb);
	--ion-color-medium-shade: #7b7e86;
	--ion-color-medium-tint: #989aa2;

	--ion-color-light: #f4f4f4;
	--ion-color-light-rgb: 244, 244, 244;
	--ion-color-light-contrast: var(--ion-color-dark);
	--ion-color-light-contrast-rgb: var(--ion-color-dark-rgb);
	--ion-color-light-shade: #d7d7d7;
	--ion-color-light-tint: #f5f5f5;

	--ion-color-osapp-element: var(--ion-color-primary);
	--ion-color-osapp-element-rgb: var(--ion-color-primary-rgb);
	--ion-color-osapp-element-contrast: var(--ion-color-primary-contrast);
	--ion-color-osapp-element-contrast-rgb: var(--ion-color-primary-contrast-rgb);
	--ion-color-osapp-element-shade: var(--ion-color-primary-shade);
	--ion-color-osapp-element-tint: var(--ion-color-primary-tint);

	--ion-color-tile-icon: #ffffff;
	--ion-color-tile-icon-rgb: 255, 255, 255;
	--ion-color-tile-icon-contrast: var(--ion-color-dark);
	--ion-color-tile-icon-contrast-rgb: var(--ion-color-dark-rgb);
	--ion-color-tile-icon-shade: #e0e0e0;
	--ion-color-tile-icon-tint: #ffffff;

	--ion-color-login: #f1f4ff;
	--ion-color-login-rgb: 241, 244, 255;
	--ion-color-login-contrast: var(--ion-color-dark);
	--ion-color-login-contrast-rgb: var(--ion-color-dark-rgb);
	--ion-color-login-shade: var(--ion-color-login);
	--ion-color-login-tint: var(--ion-color-login);

	--ion-color-sections-container: var(--ion-color-primary-contrast);

	--ion-color-task: #feb23e;
	--ion-color-task-rgb: 254, 178, 62;
	--ion-color-task-contrast: var(--ion-color-dark);
	--ion-color-task-contrast-rgb: var(--ion-color-dark-rgb);
	--ion-color-task-shade: #e09d37;
	--ion-color-task-tint: #fff0d8;

	--ion-color-done-task: #5de1b5;
	--ion-color-late-task: #f64445;
	--ion-color-deadline: #f64445;

	--ion-color-event: #76b800;
  --ion-color-event-rgb: 118, 184, 0;
  --ion-color-event-contrast: var(--ion-color-dark);
  --ion-color-event-contrast-rgb: var(--ion-color-dark-rgb);
  --ion-color-event-shade: #68a200;
  --ion-color-event-tint: #f1ffd8;

	--ion-color-documents: #ff4388;
	--ion-color-documents-rgb: 255, 67, 136;
	--ion-color-documents-contrast: #000000;
	--ion-color-documents-contrast-rgb: 0, 0, 0;
	--ion-color-documents-shade: #e03b78;
	--ion-color-documents-tint: #ff5694;

	--ion-color-opportunity: #B10031;
	--ion-color-opportunity-rgb: 177,0,49;
	--ion-color-opportunity-contrast: #ffffff;
	--ion-color-opportunity-contrast-rgb: 255,255,255;
	--ion-color-opportunity-shade: #9c002b;
	--ion-color-opportunity-tint: #b91a46;

	--ion-color-ai: #eae3f9;
	--ion-color-ai-rgb: 234, 227, 249;
	--ion-color-ai-contrast: #000000;
	--ion-color-ai-contrast-rgb: 0, 0, 0;
	--ion-color-ai-shade: #cec8db;
	--ion-color-ai-tint: #ece6fa;

	--ion-color-ai-button: #9575de;
	--ion-color-ai-button-rgb: 149, 117, 222;
	--ion-color-ai-button-contrast: #ffffff;
	--ion-color-ai-button-contrast-rgb: 255, 255, 255;
	--ion-color-ai-button-shade: #8367c3;
	--ion-color-ai-button-tint: #a083e1;

	--ion-secondary-color-event: #fae3e3;
	--ion-color-white-font: #fff;

	--ion-color-special-event-tint: #d1f0fa;
	--ion-color-user: #cfdbff;
	--ion-color-user-disable: #e2e9ff;
	--ion-color-reminder-tint: #f1f4ff;
	--ion-color-reminder-icon: #d7e1ff;
	--ion-color-done-reminder: #5de1b5;
	--ion-color-customer-icon: #ccd3ff;
	--ion-color-contact-icon: #ccd3ff;
	--ion-color-contact-item-background: #c7d5ff;
	--ion-color-site-icon: #ccd3ff;
	--ion-color-group-icon: #ccd3ff;

	--ion-color-notification: #ec8960;
	--ion-color-notification-tint: #fbe7df;

	--calao-toolbar-height: 60px;
	--ion-color-background: #f1f4ff;
	--ion-color-day-active: #c3d2ff;

	--ion-color-button: var(--ion-color-osapp-element);
	--ion-color-slidebox-active-button: var(--ion-color-background);
	--tab-bar-active-background-color: var(--ion-color-background);
	--ion-tab-bar-background: #ffffff;
	--ion-tab-bar-border-color: var(--ion-color-background);

	--calao-shadow: 2px 2px 4px 0px rgba(56, 106, 255, 0.16);
	--trade-slidebox-shadow: var(--calao-shadow);
	--trade-slidebox-shadow-active: var(--calao-shadow);

	--ion-font-family: "New Hero Bold";
	--ion-font-family: "New Hero Super";
	--ion-font-family: "New Hero ExtraBold";
	--ion-font-family: "New Hero SemiBold";
	--ion-font-family: "New Hero Italic";
	--ion-font-family: "New Hero Light";
	--ion-font-family: "New Hero UltraLigth Italic";
	--ion-font-family: "New Hero";

	--ion-button-font: "New Hero Bold";

	// Conversations
	--background-replies: var(--ion-color-primary-contrast);
	--background-sent: var(--ion-color-tertiary-tint);
	--font-black-100: var(--ion-color-dark);
	--font-color-replies: var(--ion-color-dark);
	--font-color-sent: var(--ion-color-dark);

	// Synchronisation
	--ion-color-sync: var(--ion-color-primary);

	--mdc-filled-text-field-container-color: transparent;
	--mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.12);
	--mdc-outlined-text-field-hover-outline-color: var(--ion-color-primary, lightgrey);
	--mdc-radio-selected-icon-color: var(--ion-color-primary, lightgrey);
	--mdc-chip-outline-color: transparent;
	--mdc-radio-state-layer-size: 0px;
	--mdc-filled-text-field-input-text-placeholder-color: #8e8e8e;
	--mdc-outlined-text-field-input-text-placeholder-color: #8e8e8e;
	--mat-select-placeholder-text-color: #8e8e8e;
	--mat-bottom-sheet-container-background-color: white;
	--mat-badge-background-color: var(--ion-color-primary, lightgrey);
	--mdc-chip-focus-outline-color: transparent;

	// formulaire inline
	--inline-field-clear-button-background-color: none;
	--inline-field-clear-button-font-color: var(--ion-color-medium-shade);

	--ion-fab-button-icon-size: 18px;
	--ion-fab-button-icon-width: var(--ion-fab-button-icon-size);
	--ion-fab-button-icon-height: var(--ion-fab-button-icon-size);

}

// --ion-color-osapp-element: Utilisable uniquement en tant que variable.
// .ion-color-osapp-element: Utilisable dans color="osapp-element".
.ion-color-osapp-element {
	--ion-color-base: var(--ion-color-osapp-element);
	--ion-color-base-rgb: var(--ion-color-osapp-element-rgb);
	--ion-color-contrast: var(--ion-color-osapp-element-contrast);
	--ion-color-contrast-rgb: var(--ion-color-osapp-element-contrast-rgb);
	--ion-color-shade: var(--ion-color-osapp-element-shade);
	--ion-color-tint: var(--ion-color-osapp-element-tint);
}

// --ion-color-fab-button: Utilisable uniquement en tant que variable.
// .ion-color-fab-button: Utilisable dans color="fab-button".
.ion-color-fab-button {
	--ion-color-base: var(--ion-color-osapp-element);
	--ion-color-base-rgb: var(--ion-color-osapp-element-rgb);
	--ion-color-contrast: var(--ion-color-osapp-element-contrast);
	--ion-color-contrast-rgb: var(--ion-color-osapp-element-contrast-rgb);
	--ion-color-shade: var(--ion-color-osapp-element-shade);
	--ion-color-tint: var(--ion-color-osapp-element-tint);
}

// --ion-color-button: Utilisable uniquement en tant que variable.
// .ion-color-button: Utilisable dans color="button".
.ion-color-button {
	--ion-color-base: var(--ion-color-osapp-element);
	--ion-color-base-rgb: var(--ion-color-osapp-element-rgb);
	--ion-color-contrast: var(--ion-color-osapp-element-contrast);
	--ion-color-contrast-rgb: var(--ion-color-osapp-element-contrast-rgb);
	--ion-color-shade: var(--ion-color-osapp-element-shade);
	--ion-color-tint: var(--ion-color-osapp-element-tint);
}

// --ion-color-validation: Utilisable uniquement en tant que variable.
// .ion-color-validation: Utilisable dans color="validation".
.ion-color-validation {
	--ion-color-base: var(--ion-color-success);
	--ion-color-base-rgb: var(--ion-color-success-rgb);
	--ion-color-contrast: var(--ion-color-success-contrast);
	--ion-color-contrast-rgb: var(--ion-color-success-contrast-rgb);
	--ion-color-shade: var(--ion-color-success-shade);
	--ion-color-tint: var(--ion-color-success-tint);
}

// --ion-color-slidebox-active-button: Utilisable uniquement en tant que variable.
.ion-color-slidebox-active-button {
	--ion-color-slidebox-active-button: var(--ion-color-background);
}

// --ion-color-background: Utilisable uniquement en tant que variable.
.ion-color-background {
	--ion-color-background: #f2f9fe;
}

/** Couleur de la toolbar */
.ion-color-toolbar {
	--ion-color-base: var(--ion-color-toolbar, var(--ion-color-background));
	--ion-color-base-rgb: var(--ion-color-toolbar-rgb, var(--ion-color-background-rgb));
	--ion-color-contrast: var(--ion-color-toolbar-contrast, var(--ion-color-background-contrast));
	--ion-color-contrast-rgb: var(--ion-color-toolbar-contrast-rgb, var(--ion-color-background-contrast-rgb));
	--ion-color-shade: var(--ion-color-toolbar-shade, var(--ion-color-background-shade));
	--ion-color-tint: var(--ion-color-toolbar-tint, var(--ion-color-background-tint));
}

/** Couleur des tuiles du menu*/
.ion-color-tile {
	--ion-color-base: var(--ion-color-tile, var(--ion-color-primary));
	--ion-color-base-rgb: var(--ion-color-tile-rgb, var(--ion-color-primary-rgb));
	--ion-color-contrast: var(--ion-color-tile-contrast, var(--ion-color-primary-contrast));
	--ion-color-contrast-rgb: var(--ion-color-tile-contrast-rgb, var(--ion-color-primary-contrast-rgb));
	--ion-color-shade: var(--ion-color-tile-shade, var(--ion-color-primary-shade));
	--ion-color-tint: var(--ion-color-tile-tint, var(--ion-color-primary-tint));
}

/** Couleur des icons dans les tuiles */
.ion-color-tile-icon {
	--ion-color-base: var(--ion-color-tile-icon, var(--ion-color-primary));
	--ion-color-base-rgb: var(--ion-color-tile-icon-rgb, var(--ion-color-primary-rgb));
	--ion-color-contrast: var(--ion-color-tile-icon-contrast, var(--ion-color-primary-contrast));
	--ion-color-contrast-rgb: var(--ion-color-tile-icon-contrast-rgb, var(--ion-color-primary-contrast-rgb));
	--ion-color-shade: var(--ion-color-tile-icon-shade, var(--ion-color-primary-shade));
	--ion-color-tint: var(--ion-color-tile-icon-tint, var(--ion-color-primary-tint));
}

/** Couleur des action button */
.ion-color-action-button {
	--ion-color-base: var(--ion-color-action-button, var(--ion-color-osapp-element));
	--ion-color-base-rgb: var(--ion-color-action-button-rgb, var(--ion-color-osapp-element-rgb));
	--ion-color-contrast: var(--ion-color-action-button-contrast, var(--ion-color-osapp-element-contrast));
	--ion-color-contrast-rgb: var(--ion-color-action-button-contrast-rgb, var(--ion-color-osapp-element-contrast-rgb));
	--ion-color-shade: var(--ion-color-action-button-shade, var(--ion-color-osapp-element-shade));
	--ion-color-tint: var(--ion-color-action-button-tint, var(--ion-color-osapp-element-tint));
}

/** Couleur des slidebox buttons */
.ion-color-slidebox-button {
	--ion-color-base: var(--ion-color-slidebox-button, var(--ion-color-background));
	--ion-color-base-rgb: var(--ion-color-slidebox-button-rgb, var(--ion-color-background));
	--ion-color-contrast: var(--ion-color-slidebox-button-contrast, var(--ion-color-primary));
	--ion-color-contrast-rgb: var(--ion-color-slidebox-button-contrast-rgb, var(--ion-color-background));
	--ion-color-shade: var(--ion-color-slidebox-button-shade, var(--ion-color-background));
	--ion-color-tint: var(--ion-color-slidebox-button-tint, var(--ion-color-background));
	--box-shadow: var(--trade-slidebox-shadow);
	--border-radius: 20px 20px 0 0;
}

/** Couleur du container du section expandable */
.ion-color-sections-container {
	--ion-color-base: var(--ion-color-sections-container, var(--ion-color-primary));
	--ion-color-base-rgb: var(--ion-color-sections-container-rgb, var(--ion-color-primary-rgb));
	--ion-color-contrast: var(--ion-color-sections-container-contrast, var(--ion-color-primary-contrast));
	--ion-color-contrast-rgb: var(--ion-color-sections-container-contrast-rgb, var(--ion-color-primary-contrast-rgb));
	--ion-color-shade: var(--ion-color-sections-container-shade, var(--ion-color-primary-shade));
	--ion-color-tint: var(--ion-color-sections-container-tint, var(--ion-color-primary-tint));
}

/** Redéfinition de la couleur, pour le bouton sélectionné. */
.ion-color-slidebox-button.slidebox-active-tab {
	--ion-color-base: var(--ion-color-slidebox-active-button);
	--box-shadow: var(--trade-slidebox-shadow-active);
}

.ion-color-calao-log-action {
	--ion-color-base: var(--ion-color-primary);
	--ion-color-base-rgb: var(--ion-color-primary-rgb);
	--ion-color-contrast: var(--ion-color-primary-contrast);
	--ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb);
	--ion-color-shade: var(--ion-color-primary-shade);
	--ion-color-tint: var(--ion-color-primary-tint);
}

.ion-color-calao-log-error {
	--ion-color-base: var(--ion-color-danger);
	--ion-color-base-rgb: var(--ion-color-danger-rgb);
	--ion-color-contrast: var(--ion-color-danger-contrast);
	--ion-color-contrast-rgb: var(--ion-color-danger-contrast-rgb);
	--ion-color-shade: var(--ion-color-danger-shade);
	--ion-color-tint: var(--ion-color-danger-tint);
}

.ion-color-documents {
	--ion-color-base: var(--ion-color-documents);
	--ion-color-base-rgb: var(--ion-color-documents-rgb);
	--ion-color-contrast: var(--ion-color-documents-contrast);
	--ion-color-contrast-rgb: var(--ion-color-documents-contrast-rgb);
	--ion-color-shade: var(--ion-color-documents-shade);
	--ion-color-tint: var(--ion-color-documents-tint);
}

.ion-color-ai {
	--ion-color-base: var(--ion-color-ai);
	--ion-color-base-rgb: var(--ion-color-ai-rgb);
	--ion-color-contrast: var(--ion-color-ai-contrast);
	--ion-color-contrast-rgb: var(--ion-color-ai-contrast-rgb);
	--ion-color-shade: var(--ion-color-ai-shade);
	--ion-color-tint: var(--ion-color-ai-tint);
}

.ion-color-ai-button {
	--ion-color-base: var(--ion-color-ai-button);
	--ion-color-base-rgb: var(--ion-color-ai-button-rgb);
	--ion-color-contrast: var(--ion-color-ai-button-contrast) !important;
	--ion-color-contrast-rgb: var(--ion-color-ai-button-contrast-rgb);
	--ion-color-shade: var(--ion-color-ai-button-shade);
	--ion-color-tint: var(--ion-color-ai-button-tint);
}

// Shared Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the Sass variables found in Ionic's source scss files.
// To view all the possible Ionic variables, see:
// http://ionicframework.com/docs/v2/theming/overriding-ionic-variables/

:root {
	// Classes pour le SIDE MENU.
	.expandable-separation {
		margin-top: 1px;
	}

	.side-menu-with-header {
		padding-top: 0;
		padding-bottom: 8px;
	}

	.side-menu-without-header {
		padding-top: 0px;
		padding-bottom: 0px;
	}

	// Pour la Gallery.
	.licamera-button {
		text-transform: none;
	}

	.file-picker-button {
		text-transform: none;
	}

	--custom-size-24px: 1.5rem;
	--custom-size-16px: 1rem;
	--custom-size-14px: 0.875rem;
	--custom-size-12px: 0.75rem;
	--custom-size-10px: 0.625rem;
}

@font-face {
	font-family: "New Hero";
	font-style: normal;
	font-weight: 500;
	src: url("../assets/fonts/new-hero-regular.otf");
}

@font-face {
	font-family: "New Hero Light";
	font-style: normal;
	font-weight: 300;
	src: url("../assets/fonts/new-hero-light.otf");
}

@font-face {
	font-family: "New Hero Italic";
	font-style: normal;
	font-weight: 300;
	src: url("../assets/fonts/new-hero-italic.otf");
}

@font-face {
	font-family: "New Hero UltraLigth Italic";
	font-style: normal;
	font-weight: 300;
	src: url("../assets/fonts/new-hero-ultralight-italic.otf");
}

@font-face {
	font-family: "New Hero SemiBold";
	font-style: normal;
	font-weight: 700;
	src: url("../assets/fonts/new-hero-semi-bold.otf");
}

@font-face {
	font-family: "New Hero Bold";
	font-style: normal;
	font-weight: 700;
	src: url("../assets/fonts/new-hero-bold.otf");
}

@font-face {
	font-family: "New Hero ExtraBold";
	font-style: normal;
	font-weight: 700;
	src: url("../assets/fonts/new-hero-extra-bold.otf");
}

@font-face {
	font-family: "New Hero Super";
	font-style: normal;
	font-weight: 700;
	src: url("../assets/fonts/new-hero-super.otf");
}

// Named Color Variables
// --------------------------------------------------
// Named colors makes it easy to reuse colors on various components.
// It's highly recommended to change the default colors
// to match your app's branding. Ionic uses a Sass map of
// colors so you can add, rename and remove colors as needed.
// The "primary" color is the only required color in the map.

// App Variables globales à toutes les plateformes

// App iOS Variables
// --------------------------------------------------
// iOS only Sass variables can go here
.ios {
}

// App Material Design Variables
// --------------------------------------------------
// Material Design only Sass variables can go here
.md {
}

// App Theme
// --------------------------------------------------
// Ionic apps can have different themes applied, which can
// then be future customized. This import comes last
// so that the above variables are used and Ionic's
// default are overridden.

// @import "ionic.theme.default";
@import "ionic.theme.calao";
// @import "ionic.theme.dark";

// Ionicons
// --------------------------------------------------
// The premium icon font for Ionic. For more info, please see:
// http://ionicframework.com/docs/v2/ionicons/

// @import "ionicons";
// @import "style";

// Fonts
// --------------------------------------------------

@import "../assets/fonts/roboto.scss";
@import "../assets/fonts/noto-sans.scss";
