// http://ionicframework.com/docs/theming/
@use "@angular/material" as mat;
@use "theme/m3-theme.scss";

@import "../../../node_modules/@ionic/angular/css/core.css";
@import "../../../node_modules/@ionic/angular/css/display.css";
@import "../../../node_modules/@ionic/angular/css/normalize.css";
@import "../../../node_modules/@ionic/angular/css/structure.css";
@import "../../../node_modules/@ionic/angular/css/typography.css";

@import "../../../node_modules/@ionic/angular/css/padding.css";
@import "../../../node_modules/@ionic/angular/css/float-elements.css";
@import "../../../node_modules/@ionic/angular/css/text-alignment.css";
@import "../../../node_modules/@ionic/angular/css/text-transformation.css";
@import "../../../node_modules/@ionic/angular/css/flex-utils.css";
@import "../../../node_modules/angular-calendar/css/angular-calendar.css";
@import "material-icons/iconfont/material-icons.css";

@include mat.core();

@import "~css-star-rating/css/star-rating.min.css";
.rating {
	margin-bottom: 0;
}

html {
	// Apply the light theme by default
	@include mat.core-theme(m3-theme.$light-theme);
	@include mat.all-component-themes(m3-theme.$light-theme);
}

// Largeur form field material
.mat-mdc-form-field {
	width: 100%;
}

// Masquage bloc bottom form field material
.mat-mdc-form-field-subscript-wrapper {
	display: none;
}

// Masquage border material
.mdc-line-ripple {
	display: none;
}

// Masquage effet ripple overlay form-field
.mat-mdc-form-field-focus-overlay {
	display: none;
}

// Padding field formulaire
.mat-form-field-appearance-outline {
	color: var(--ion-color-primary, lightgrey);
	.mat-mdc-form-field-infix {
		padding-bottom: 4px !important;
		padding-top: 4px !important;
		min-height: 0;
	}
}

.mat-form-field-appearance-fill {
	padding-bottom: 0 !important;
	.mat-mdc-form-field-infix {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
		min-height: 0;
	}

	.mat-mdc-text-field-wrapper {
		padding: 0 !important;
		overflow: visible !important;
	}
}

/* Padding supplémentaire pour les formulaires n'ayant pas encore le style inline, à supprimer par la suite */
trade-contact-edit,
trade-group-edit,
trade-user {
	.mat-form-field-appearance-outline {
		padding-bottom: 0.5em !important;
	}

	.mat-form-field-appearance-fill {
		.mat-mdc-form-field-infix {
			padding-top: 0.2em !important;
			padding-bottom: 0.4em !important;
		}
	}
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
	background: var(--ion-color-primary, lightgrey);
}

.mat-badge-content {
	color: #fff;
	background: var(--ion-color-primary, lightgrey);
	z-index: 1;
	position: absolute;
	text-align: center;
	display: inline-block;
	border-radius: 50%;
	transition: transform 200ms ease-in-out;
	transform: scale(0.6);
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	pointer-events: none;
}

.user-contact .mat-badge-content {
	border-color: var(--ion-color-tertiary) !important;
}

.notif-badge .mat-badge-content {
	background: var(--ion-color-warning, lightgrey);
}

.notif-badge {
	position: relative;
}

.mat-mdc-chip.mat-mdc-standard-chip,
.mat-mdc-chip.mat-standard-chip:focus,
.mat-mdc-chip.mat-standard-chip:hover,
.mat-mdc-chip.mat-standard-chip::after {
	border: 1px solid var(--ion-color-primary);
	background-color: var(--ion-color-primary-contrast) !important;
	color: var(--ion-color-primary);
}

.mat-mdc-chip.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary {
	border: 1px solid var(--ion-color-primary);
	background-color: var(--ion-color-primary) !important;
	color: var(--ion-color-primary-contrast);

	span {
		color: var(--ion-color-primary-contrast) !important;
	}
}

.mat-mdc-standard-chip {
	border-radius: 16px !important;
	height: 32px !important;
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
	color: var(--ion-color-primary) !important;
}

.mat-datepicker-toggle-active {
	color: var(--ion-color-primary, lightgrey);
}

.mat-icon.mat-primary {
	color: var(--ion-color-primary, lightgrey);
}

.mat-mdc-input-element {
	caret-color: var(--ion-color-primary, lightgrey);
}

.mat-mdc-progress-spinner circle,
.mat-mdc-progress-spinner circle {
	stroke: var(--ion-color-primary, lightgrey);
}

.mat-mdc-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
	background-color: var(--ion-color-primary, lightgrey);
}

.mat-mdc-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
	background-color: var(--ion-color-primary, lightgrey);
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
	background-color: var(--ion-color-primary, lightgrey);
	color: #fff;
}

.mat-mdc-tab-group.mat-primary .mat-ink-bar,
.mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
	background-color: var(--ion-color-primary, lightgrey);
}

.mat-mdc-tab-group.mat-background-primary .mat-mdc-tab-header,
.mat-tab-group.mat-background-primary .mat-mdc-tab-links,
.mat-mdc-tab-nav-bar.mat-background-primary .mat-tab-header,
.mat-tab-nav-bar.mat-background-primary .mat-tab-links {
	background-color: var(--ion-color-primary, lightgrey);
}

mat-form-field mat-radio-button {
	padding: 10px 16px 10px 0;
}

/* Date range picker */
.calao-date-range-picker {
	.mat-mdc-form-field {
		padding-bottom: 0px !important;
		height: 48px;
	}
	.mat-form-field-outline {
		height: 48px;
	}
	.mat-mdc-form-field-infix {
		margin: 0;
		border: 0;
	}
}

calao-filter-bar-item calao-date-range-picker mat-form-field {
	margin-bottom: 10px;
}

.txt-right {
	margin-left: auto;
	margin-right: 0;
}

.mat-datepicker-popup {
	position: unset;
	margin: auto;
	top: unset;
	left: unset;
}

ion-toolbar {
	ion-badge.ios {
		right: 0px;
		top: 9px;
	}
}

ion-toolbar {
	calao-database-sync-status-icon ion-button,
	.buttons-last-slot calao-database-sync-status-icon ion-button {
		height: 42px !important;
		width: 42px !important;
		border-radius: 14px;
	}

	.navbar {
		.sc-ion-buttons-md-h {
			margin-top: 0;
		}

		ion-title.md {
			margin-top: 0;
		}

		.buttons-last-slot ion-button {
			height: 48px !important;
			width: 48px !important;
		}

		.sc-ion-buttons-md-s .button-has-icon-only.button-clear {
			--padding-start: 5px;
			--padding-end: 5px;
		}
	}
}

.z-index-max {
	z-index: 999999 !important;
}

.disabled {
	opacity: 0.3;
	pointer-events: none;
	cursor: default;
}

a {
	color: var(--ion-color-osapp-element);
}

// @import '@osapp/src/theme/material.scss; TODO voir comment importer un ce fichier

/* Permet de centrer un élément dans une page */
.middle-align {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

ion-toast::part(button) {
	font-family: "New Hero Bold";
	background: var(--ion-color-primary);
	color: var(--ion-color-primary-contrast);
	border-radius: 50px;
	text-transform: none;
	margin-left: 5px;
	padding: 5px;
	padding-inline: 10px;
}

[hidden] {
	display: none !important;
}

.input-highlight,
.textarea-highlight {
	display: none;
}

//#region searchbar

.search-bar-area .mat-mdc-text-field-wrapper {
	border-radius: 50px !important;
	border: 1px solid var(--ion-color-primary);
	overflow: hidden;
	--mdc-outlined-text-field-hover-outline-color: transparent;
	--mdc-outlined-text-field-focus-outline-color: transparent;
	--mat-form-field-leading-icon-color: var(--ion-color-osapp-element);
}

//#endregion searchbar

.linked-entities-icons-ctn {
	display: flex;
	gap: 4px;
	margin-left: 4px;

	.icon-ctn {
		background-color: white;
		width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
	}

	ion-icon {
		margin-right: 0px;
		font-size: 10px;
	}
}