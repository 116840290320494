/* ionic.globals.scss /////
 * Description : Classes utilitaires de l'application
*/

/* Classes génériques réutilisables */
.no-margin {
	margin: 0;
}

.blue-fab-button {
	--background: var(--ion-color-osapp-element);
}

.orange-fab-button {
	--background: var(--ion-color-warning);
}

.pointer {
	cursor: pointer;
}

.opacity60 ion-icon {
	opacity: 60%;
}

.uppercase {
	text-transform: uppercase !important;
}

.margin-t10 {
	margin-top: 10px;
}

/* Définition des fonts */

ion-title {
	font-family: "New Hero Bold";
	font-size: 18px !important;
}

ion-label {
	font-size: 16px;
}

ion-note {
	color: var(--ion-color-medium) !important;
	font-size: 12px !important;
}

textarea {
	font-family: "New Hero";
	color: var(--ion-color-dark) !important;
}

ion-badge,
.badge {
	border-radius: 100%;
	color: var(--ion-color-warning-contrast) !important;
}

.toolbar.btn-fab {
	box-shadow: var(--calao-shadow) !important;
}

.ion-button-text {
	font-family: "New Hero Bold";
	font-size: 14px;
}

.title-card {
	color: var(--ion-color-sections-container);
}

.txt-important {
	color: var(--ion-color-primary) !important;
}

.slidebox-nav ion-button {
	font-family: "New Hero Bold";
	font-size: 14px;

	span::first-letter {
		text-transform: uppercase;
	}
}

mat-chip-option ion-label {
	font-size: 14px !important;
}

/* Side panel secondaire */
calao-link .standard-link {
	ion-icon {
		margin: 16px 2px !important;
		font-size: 24px !important;
		color: var(--ion-color-medium) !important;
	}

	ion-label {
		font-size: 12px !important;
		margin: 16px 8px;
		color: var(--ion-color-medium) !important;
	}
}

/* Tuiles de la page d'accueil */

ion-item.button-link-tile {
	--inner-padding-end: 0;
	--padding-start: 0;
}

.button-link-tile {
	--background: var(--ion-color-primary) !important;
	border-radius: 20px !important;
}

/* Affichage du header */
ion-header {
	background: var(--ion-color-background);
}

ion-toolbar {
	color: var(--ion-color-dark);
	--background: var(--ion-color-background);

	ion-title {
		padding: 0 10px;
	}
}

ion-footer ion-toolbar,
mat-bottom-sheet-container ion-toolbar {
	--background: var(--ion-color-primary-contrast);
}

ion-list-header ion-label {
	font-family: "New Hero Bold";
	font-size: 16px;
}

mat-bottom-sheet-container {
	border-top-left-radius: 14px !important;
	border-top-right-radius: 14px !important;
}

ion-modal ion-content {
	--padding-top: 10px !important;
	--padding-bottom: 10px !important;
	--padding-start: 10px !important;
	--padding-end: 10px !important;
}

ion-modal ion-toolbar.ios {
	height: 60px;
	padding: 10px 0;
}

ion-modal.transparent {
	--background: none !important;
}

ion-toolbar ion-title.ios {
	padding: 0 10px;
	position: relative;
	text-align: left;
	font-weight: 500;
}

.buttons-last-slot ion-button,
calao-database-sync-status-icon ion-button,
.options-btn {
	width: 48px !important;
	height: 48px !important;
	margin: 2px !important;
	color: var(--ion-color-primary);
}

calao-dynamicpage ion-header .buttons-last-slot {
	margin-inline-end: 4px !important;
}

calao-dynamicpage ion-header .buttons-last-slot.ios {
	margin-inline-end: 0px !important;
}

.secondary-toolbar osapp-date-time {
	ion-item {
		--padding-start: 0;
		padding-left: 0 !important;

		.pad-hour {
			padding-left: 8px;
			font-weight: bold;
			color: var(--ion-color-osapp-element);
			line-height: 22px;
			font-size: 16px;
			text-decoration: underline;
		}
	}
}

/* Affichage des boutons */
.button-link {
	text-decoration: underline;
}

ion-footer {
	padding: 6px;

	ion-button {
		--padding-start: 14px;
		--padding-end: 14px;
	}
}

/* Page contacts et groupes */

.last-name,
.first-name {
	font-family: "New Hero Bold" !important;
	height: 22px;
}

contacts-dyn-host {
	ion-text .name {
		display: flex;
		flex-direction: row;
		align-items: baseline;

		ion-label {
			padding-right: 4px;
		}
	}
}

.group-name {
	font-family: "New Hero Bold" !important;
}

contacts-book {
	.round {
		background-color: var(--ion-color-contact-icon) !important;
	}
}

.text-container {
	justify-content: center;
}

.btn-more ion-icon {
	color: var(--ion-color-dark);
}

.btn-more.btn-white ion-icon {
	color: white;
}

ion-button.btn-more {
	pointer-events: auto;
}

.item-sliding-active-slide.item-sliding-active-options-start .item-options-start,
.item-sliding-active-slide.item-sliding-active-options-end ion-item-options:not(.item-options-start) {
	width: auto;
}

.swipe-btn {
	font-family: "New Hero Bold";

	ion-icon {
		font-size: 25px;
		margin-right: 5px;
	}
}

ion-item.user-contact {
	--background: white !important;

	ion-label {
		color: var(--color) !important;
	}

	ion-note {
		color: var(--ion-color-medium) !important;
	}
}

/* Classes permettant une diminution des tailles de contactsList dans l'onglet Prescription */
ion-col.ion-no-padding contacts-list div ion-grid {
	padding: 0;

	ion-row ion-col {
		padding: 0;

		ion-list {
			padding: 0;

			ion-item {
				--min-height: 24px;
				--max-height: 24px;

				ion-label {
					margin-top: 0;
					margin-bottom: 0;
				}
			}
		}
	}
}

/* Fin de la classe pour l'onglet prescription */

formly-group {
	width: 100%;
}

formly-field.gradient formly-group {
	display: block;
	align-items: flex-end;
	flex: 1 0 auto;
	position: absolute;
	bottom: 0;
	left: 0;
}

formly-field.width25pc formly-wrapper-ion-form-field ion-item {
	--padding-start: 15%;
}

.log {
	position: absolute;
	z-index: -10;
	transform: translate(0, 50%);
}

// Boutons de connexion / creation de compte
.auth .button-md.button-md-primary,
.auth .button-md.button-md-secondary {
	padding: 12px;
}

.auth .button-md.button-md-secondary {
	color: #0066cc;
}

.centera {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.auth {
	height: 100%;
	width: 100%;
	background-color: cornflowerblue;
	background-image: radial-gradient(white, cornflowerblue);
}

.password-forgotten + .centera,
.password-input + .centera,
.content-center .centera {
	ion-button {
		--ion-color-base: var(--ion-color-osapp-element) !important;
		--border-radius: 100px;
		font-family: "New Hero Bold";
		font-size: 14px;
		--box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
		width: 150px;
		height: 40px;
		--padding-start: 20px;
		--padding-end: 20px;
	}
}

.password-forgotten + .centera {
	align-items: flex-end;

	ion-button {
		width: auto;
		height: 40px;
	}
}

.circle-pin-container {
	ion-icon {
		color: cornflowerblue !important;
	}
}

.password-forgotten ion-button {
	--ion-color-light: var(--ion-color-primary);
}

.pin-password-input-container {
	--ion-color-primary-contrast: var(--ion-color-primary-tint, white);

	.validate-icon {
		top: 3px !important;
	}
}

.member ion-label {
	font-family: "New Hero Bold" !important;
}

:host(.button-clear.ion-color) .button-native {
	color: var(--ion-color-primary);
}

.mode-autentification {
	ion-icon {
		height: 15px;
		width: 15px;
		padding-bottom: 3px !important;
	}

	ion-label {
		padding-left: 10px !important;
	}
}

.content-input,
calao-change-password .password-input,
calao-reset-password .password-input {
	border-radius: 40px !important;
	border: 1px solid var(--ion-color-light-shade);
}

.input-authentification,
.password-input {
	border-radius: 40px !important;

	ion-input {
		height: 45px;
		text-align: left !important;
		--padding-start: 15px !important;
	}

	.pswd-visibility {
		height: 20px;
		color: var(--ion-color-osapp-element) !important;
	}
}

.btn-invite {
	font-family: "New Hero" !important;
	text-decoration: underline;
	font-size: 14px !important;
}

/*#region Framework Application /////////////////////////// */
/*#region Flex -------------------------------------------- */
.dis-flex {
	display: flex;
	flex-wrap: wrap;
}

.visu {
	formly-group {
		display: flex;
	}
}

.just-flex {
	display: flex;
}

.flex-no-wrap {
	flex-wrap: nowrap;
}

.dis-line-flex {
	display: inline-flex;
}

.dis-line {
	display: inline-block;
	vertical-align: top;
}

.dis-block {
	display: block;
}

/* Position */
.pos-rel {
	position: relative;
}

.pos-absrel {
	position: absolute;
	bottom: 0;
	left: 0;
}

.pos-stat {
	position: static;
}

/* Direction */
.flex-col {
	flex-direction: column;
}

.flex-row {
	flex-direction: row;
}

/* Grow */
.flx-grow1 {
	flex: 1;
	align-items: center;
}

.flx-grow-1Auto {
	flex: 1 0 auto;
}

/* Justify */
.flex-justify-center {
	justify-content: center;
}

.flex-justify-space-between {
	justify-content: space-between;
}

/* Alignement */
.col-center {
	align-self: center;
}

.col-bottom {
	align-self: flex-end;
}

//*#region Row & Columns
/* .row-* vertically aligns every .col in the .row */
.row-wrap {
	flex-wrap: wrap;
}

.row-center {
	align-items: center;
}

.row-bottom {
	align-items: flex-end;
}

.marg-fav {
	padding-left: 4px;
}

.table-header {
	width: calc(100% - 12px);
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	overflow: hidden;
}

/*#endregion Flex ----------------------------------------- */

/*#region Texte ------------------------------------------- */
.width100pc {
	width: 100%;
}

.width90pc {
	width: 90%;
}

.width70pc {
	width: 70%;
}

.width40pc {
	width: 40%;
}

.width50pc {
	width: 50%;
}

.width60pc {
	width: 60%;
}

.width30pc {
	width: 30%;
}

.width25pc {
	width: 25%;
}

.width20pc {
	width: 20%;
}

.width10pc {
	width: 10%;
}

.min-width {
	min-width: 50%;
}

.max-width50pc {
	max-width: 50%;
}

/*#endregion Texte ------------------------------------------- */

/*#region Texte ------------------------------------------- */
.txt-center {
	text-align: center;
}

.txt-right {
	text-align: right;
}

.txt-left {
	text-align: left;
}

.txt-legende {
	font-size: 0.75rem;
	color: #505050;
}

.txt-info {
	font-family: "New Hero Bold";
}

.cicon {
	font-size: 2.5em !important;
	color: white;
}

.txt100px {
	font-size: 100px;
}

.txt24px {
	font-size: 24px;
}

.txt16px {
	font-size: 16px;
}

.txt14px {
	font-size: 14px;
}

.txt12px {
	font-size: 12px;
}

.txt10px {
	font-size: 10px;
}

/*#endregion Texte ---------------------------------------- */

/*#region CSS customersBook*/

.ctn-entete {
	formly-group {
		width: 100%;
		justify-content: center;
	}
}

.center {
	formly-group {
		formly-field {
			margin: auto;
		}
	}
}

.ctn-entete > formly-group:first-of-type > formly-form:first-of-type {
	display: flex;
	flex-wrap: wrap;
}

.ctn-infos > formly-group:first-of-type > formly-form:first-of-type {
	flex-direction: column;
	height: 100%;
	display: flex;
}

.ctn-infos formly-group {
	display: block;
	justify-content: left;
}

.infos-area {
	width: calc(100% - 35px);

	formly-group {
		flex-direction: column;
	}

	.name-area {
		formly-group {
			flex-direction: row;
		}
	}
}

.marg-infos {
	width: calc(100% - 16px);
}

.name-area {
	.marg-infos {
		width: 100%;
	}
}

.pathology-area {
	.text-align-report {
		display: flex;

		.marg-infos {
			max-width: 100%;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			overflow: hidden;
		}
	}
}

.ctn-infos .width100pc formly-group {
	display: flex;
}

.marg-btm {
	margin-bottom: 5rem;
}

.pad-tp {
	padding-top: 0.75rem;
}

.ctn-infos {
	width: calc(100% - 150px);
	max-width: 280px;

	ion-item {
		font-size: var(--custom-size-12px);
	}
}

.ctn-infos.dis-flex {
	height: 100%;
	padding-top: 0.5rem;
	padding-left: 0.5rem;
	background: white;
	margin: 10px 10px 10px 0;
	border-radius: 8px;
	box-shadow: var(--calao-shadow);
}

ion-item.member-height {
	--background-color: white;
	background: white;
	height: 36px;
}

.ctn-infos.pos-rel.dis-line.height100pc.width50pc {
	padding-top: 0.5rem;
	padding-left: 0.5rem;
	background: white;
	background: white;
}

.align-sector {
	formly-group {
		display: flex;
		align-items: center;
	}

	ion-item {
		--inner-padding-end: 0 !important;
	}

	.no-pad-sector ion-item {
		--padding-start: 0;
	}

	ion-item.pad-left-txt {
		font-size: 14px;
		padding-bottom: 0;
		white-space: nowrap !important;
		text-overflow: ellipsis;
		overflow: hidden;
		text-decoration: none;
	}
}

.city formly-field {
	padding-right: 4px;
}

.zip-code ion-item {
	padding-left: 0.3rem;
}

.ctn-actions-btn {
	margin: auto;
	margin-top: 5px;
	width: 100%;

	formly-group {
		display: flex;
		justify-content: center;
	}

	.hidden-button {
		display: none;
	}
}

trade-customer-form {
	.ctn-actions-btn {
		max-width: 390px;
		display: flex;
	}

	.contacts-list {
		padding-left: 0;
	}

	.date-label {
		ion-item {
			margin-bottom: 12px;

			div {
				display: flex;
				flex-direction: column;

				ion-label {
					width: 170px;
				}

				ion-label:first-of-type {
					color: #717171 !important;
					font-size: 15px !important;
					margin-bottom: 4px;
				}
			}
		}
	}
}

.img {
	background: var(--ion-color-tertiary);
	color: var(--ion-color-tertiary-contrast);
}

.pad-left-trait {
	padding-left: 0.8rem;
}

formly-wrapper-mat-form-field {
	display: flex;
}

date-time-spinner {
	ion-item {
		height: 48px;
	}
}

.marge-btm-list {
	margin-bottom: 5rem;
}

.marg-infos {
	max-width: 80%;

	a {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		text-decoration: none;
	}

	span {
		overflow: initial;
	}
}

.padd-customer ion-item.ios {
	padding-bottom: 1rem;
}

.padd-btm-affection ion-item.ios {
	padding-bottom: 1rem;
}

formly-field.txt-col-info.txt14px ion-item {
	height: 2rem;
	margin-bottom: 0.5rem;
}

formly-field.dis-block.txt14px ion-item {
	margin-bottom: 1rem;
}

formly-field.label-mutual ion-item {
	--padding-start: 0;
	padding-top: 1rem;

	ion-label {
		color: #717171 !important;
		font-size: 15px !important;
	}
}

.fab-list-text {
	display: flex;
	justify-content: flex-end;
}

.fab-shadow {
	--box-shadow: var(--calao-shadow);
	box-shadow: var(--calao-shadow);
	border-radius: 50px;
}

.fab-label {
	.label {

		display: flex;
		flex-direction: row;
		align-items: center;

		ion-icon {
			width: var(--ion-fab-button-icon-width);
			height: var(--ion-fab-button-icon-height);
			margin-right: 5px;
		}
	}
}
// Réduit la taille des boutons sur les devices de petite taille (ex iphone SE)
@media (max-height: 684px) {
	ion-fab-list .fab-label {
		height: 36px;
	}
}

.center-explains {
	padding: 2px 10px;
	width: 100%;

	.mat-mdc-form-field-wrapper {
		background: white;
		border-radius: 4px;
	}
}

.purpose.center-explains {
	padding: 10px;
	width: auto;
	display: flex;
	background: white;
	margin: 5px 10px;
	border-radius: 8px;

	ion-label {
		font-size: 14px;
		width: 100%;
	}
}

osapp-date-time.hidden {
	visibility: collapse;
	height: 0px;
	width: 0px;
	display: flex;
}

.first-visit-date osapp-date-time {
	ion-item {
		--padding-start: 2px;

		.txt-date {
			display: none;
		}
	}
}

.pad-geoloc {
	padding: 6px 0 24px 0;
}

/* Edit fiche contact et customer */

.empty-list-message {
	color: white;
}

.ctn-infos.dis-flex {
	padding-left: 20px !important;
}

.cy-customer-gender ion-item {
	margin-top: 0.5rem;
}

.groups-list calao-checkbox ion-item {
	--padding-start: 0;
}

calao-groups-checklist ion-list-header ion-label {
	margin: auto;
}

.txt-col-info readonly-wrapper ng-component ion-item ion-icon.margelect,
.txt-col-info + formly-field readonly-wrapper ng-component ion-item ion-icon.margelect {
	font-size: 20px !important;
}

.txt-col-info readonly-wrapper ng-component ion-item ion-icon.margelect + ion-label,
.txt-col-info + formly-field readonly-wrapper ng-component ion-item .marg-infos span {
	font-size: 14px !important;
	color: var(--ion-color-medium) !important;
}

ion-slide .width100pc.dis-flex.pad-infos {
	border-radius: 20px !important;
	padding-bottom: 20px;
}

/* page contact et groupe */

.txt-contacts-list {
	color: var(--ion-color-osapp-element) !important;
}

ion-list .button-round,
ion-chip {
	height: 30px;
}

/* Num RPPS*/
mat-checkbox {
	padding-left: 0.5rem;
}

.mat-mdc-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
	background-color: transparent !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
	background-color: (var(--ion-color-primary)) !important;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
	background-color: grey !important;
}

:root {
	ion-img {
		/**
     * Bug fix for:
     * https://github.com/ionic-team/ionic/issues/18734
     */
		min-height: 1px !important;
	}

	.picture-edit ion-img {
		height: 8rem;
	}

	.img-area .picture-edit ion-img {
		height: 8rem;
	}
}

/* Affichage du bouton de filtrage */
.filter-button-right {
	--overflow: visible;
	height: 42px;
	margin-top: 14px;
	margin-bottom: auto;
	padding-right: 6px;
	margin-right: 12px;
}

.filter-button,
.filter-button-right {
	--border-radius: 50px !important;
	width: 34px !important;
	height: 34px !important;
	padding: 0;
	--padding-start: 10px;
	--padding-end: 10px;
}

/* Barre de recherche */
.searchbar-input,
.searchbar-input-container,
.searchBox-group .mat-mdc-form-field-wrapper {
	border-radius: 50px !important;
	border: 1px solid var(--ion-color-primary);
}

.searchBox-group {
	.mat-mdc-form-field-wrapper {
		box-shadow: var(--calao-shadow);
		.mat-mdc-form-field-flex {
			margin: auto;
		}

		.search-icon {
			margin-right: 6px !important;
		}
	}

	input,
	input::placeholder {
		text-overflow: ellipsis !important;
		overflow: hidden;
		white-space: nowrap;
		font-style: normal;
		font-size: 16px;
		width: 100%;
	}

	input {
		color: var(--ion-color-dark);
	}

	input::placeholder {
		color: var(--ion-color-medium);
	}
	.mat-form-field-suffix button {
		color: var(--ion-color-osapp-element);
	}
}

ion-input input {
	border-radius: 0 !important;
}

/* Bordure des mat-form-fields outline */
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
	color: var(--ion-color-primary) !important;
	.mat-form-field-outline-start,
	.mat-form-field-outline-gap,
	.mat-form-field-outline-end {
		border-width: 1px !important;
	}
}

.searchbar-input,
.searchbar-search-icon,
.searchBox-group .mat-mdc-form-field-wrapper {
	text-align: left !important;
	font-style: italic !important;
}

.searchbar-input {
	--ion-color-base: var(--ion-color-osapp-element-contrast) !important;
	box-shadow: var(--calao-shadow) !important;
	text-overflow: ellipsis !important;
	overflow: hidden;
	white-space: nowrap;
	width: 100%;
}

.searchbar-clear-button {
	color: var(--ion-color-osapp-element) !important;
}

//supprime le outline de la searchbar
search {
	.mat-form-field-appearance-outline .mat-form-field-outline-start,
	.mat-form-field-appearance-outline .mat-form-field-outline-end {
		border: 0;
	}
}

/* Affichage du calendrier */
calao-date-range-picker {
	mat-form-field-flex {
		background: white;
	}
}

/* Composant de filtrage */
.filter {
	--ion-color-base: white !important;
	color: var(--ion-color-osapp-element) !important;
}

.filter ion-button:hover {
	color: var(--ion-color-sections-container);
}

.tags-list-label {
	color: var(--ion-color-medium) !important;
}

.reset-filter {
	color: var(--ion-color-osapp-element);
}

.delete-button {
	margin-left: auto !important;
	margin-right: auto !important;
}

.delete-button ion-icon {
	height: 15px !important;
	margin-right: 5px !important;
}

.delete-button:hover {
	color: var(--ion-color-sections-container);
}

/* Affichage des tags modes de tournée */
ion-buttons osapp-selector.selector-tags {
	margin: auto;
	padding-right: 0.5rem;

	.tags-list {
		padding: 0 !important;
	}
}

/* Alignement barre de recherche */
.cdk-virtual-scroll-orientation-vertical .cdk-virtual-scroll-content-wrapper {
	max-width: 100%;
}

.filter-button ion-button {
	border-radius: 20px;
}

/* Affichage pour le contenu d'une séance dans les tournées et les events  */
mat-expansion-panel.eventOccurrence-list {
	trade-surveillances-list,
	transmissions-list {
		ion-item.search-item {
			display: none;
		}

		ion-list {
			margin-bottom: 0;
		}

		.empty-screen {
			padding-top: 0;
		}
	}
}

.ctn-info-eventOccurrences {
	cdk-virtual-scroll-viewport {
		border-radius: 20px;
	}
}

.ctn-detail-calculs-eventOccurrence {
	.calao-virtual-scroll-wrapper {
		box-shadow: none;
		margin: 10px;
	}

	.cdk-virtual-scroll-content-wrapper {
		box-shadow: var(--calao-shadow);
		padding-bottom: 10px;
	}

	.cdk-virtual-scroll-content-wrapper {
		background: white;
		border-radius: 0 0 14px 14px;
		box-shadow: var(--calao-shadow);
		padding-bottom: 10px;
	}
}

.list-eventOccurrences-majo {
	.calao-virtual-scroll-wrapper {
		box-shadow: none !important;
	}

	cdk-virtual-scroll-viewport {
		border-radius: 0 0 14px 14px;
	}

	.cdk-virtual-scroll-content-wrapper {
		background: white;
		border-radius: 0 0 14px 14px;
		box-shadow: var(--calao-shadow);
		padding-bottom: 10px;
	}
}

.eventOccurrence-price avatar ion-icon {
	padding: 4px !important;
}

.avatar-infos avatar img {
	display: flex;
}

.pad-etat avatar ion-icon {
	display: flex;
	background: transparent !important;
}

/* Affichage des séances annulées dans les tournées  */
mat-expansion-panel.canceled-eventOccurrences {
	.mat-expansion-panel-body {
		padding: 0;
	}
}

/* Edition d'un acte */
.tags-days osapp-selector .tags-list {
	padding: 0 10px 4px;
}

mat-chip-option ion-label {
	padding: 0 0.4rem !important;
}

.modal-acte-manager {
	.mat-expansion-panel-body {
		padding: 0;
	}
}

/* Affichage des items des events*/
.treatment-item {
	display: flex;
	flex-direction: row;

	.pad-infos-events {
		max-width: 70%;

		.number-eventOccurrences {
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 1;
			overflow: hidden;
		}
	}

	.treatment-icons-area {
		margin-left: auto;
		margin-right: 10px;
	}
}

/* Taille de modal personnalisée */
.small::part(content) {
	width: 300px !important;
	height: 360px !important;
}

.large::part(content) {
	width: 500px !important;
	height: 350px !important;
}

.time-picker::part(content) {
	width: 300px !important;
	height: 180px !important;
	border-radius: 4px;
}

.xs::part(content) {
	width: 300px !important;
	height: 180px !important;
}

.medium::part(content) {
	width: 400px !important;
	height: 500px !important;
}

.range-picker::part(content) {
	width: 300px !important;
	height: 180px !important;
}

.sc-ion-modal-md-h:first-of-type {
	--box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
}

/* Modale planning RH */
ion-alert.delete-hour-planning {
	.alert-title {
		font-size: 18px;
	}

	.alert-sub-title {
		color: var(--ion-color-danger);
	}
}

/* Modale tournée conflits */
.tournee-conflicts::part(content) {
	min-height: 50px;
	max-height: 180px;
}

.screen::part(content) {
	width: 100% !important;
	height: 100% !important;
}

/* Affichage de la snack-bar */
.mat-mdc-snack-bar-container {
	background: var(--ion-color-primary) !important;
	color: var(--ion-color-primary-contrast) !important;
	border: 1px solid var(--ion-color-login);

	.mat-mdc-button,
	.mat-mdc-icon-button,
	.mat-mdc-outlined-button {
		color: var(--ion-color-primary-contrast) !important;
		text-transform: uppercase;
	}
}

/* Affichage transmissions et fonts */
transmissions-list {
	.tags-list {
		display: flex;
		flex-direction: row;
		align-items: center;

		.tags-list-container {
			padding-left: 8px;
		}
	}

	.hide-status {
		.actif {
			display: none;
		}
	}

	.actif {
		margin-bottom: 2px;
	}

	.actif {
		ion-icon {
			height: 9px;
			width: 9px;
		}

		ion-note {
			font-size: 12px !important;
		}
	}
}

/* Affichage tournées */
trade-tournees-page {
	ion-fab ion-fab-button {
		ion-icon {
			font-size: 2rem;
		}
	}

	osapp-date-time ion-item {
		--ion-color-base: transparent !important;
		--ion-color-contrast: var(--ion-color-primary) !important;
	}
}

/* Switch bouton à utiliser seulement en mode ios */
ion-toggle {
	--handle-height: 20px;
	height: 24px;
	--handle-width: 20px;
	width: 40px;
}

ion-toggle::part(track) {
	height: 24px;
	width: 40px;
}

/* Selection date */

.center-date {
	color: var(--ion-color-osapp-element);

	ion-icon {
		color: var(--ion-color-osapp-element);
		margin: 8px 2px;
	}
}

.center-date {
	border-radius: 20px;
	--padding-start: 0px;
	--inner-padding-end: 4px;
}

calao-inline-field-layout-bottom-content {
	.txt-date {
		padding-left: 0px !important;
	}
}

trade-acte-edit osapp-date-time .pad-hour {
	font-size: 14px !important;
}

/* Bouton menu */

.button-clear {
	--color: var(--ion-color-osapp-element);
}

/* Checkbox */

ion-checkbox {
	--border-radius: 20px;
	--size: 20px;
	--border-width: 2px;

	&.font-14 {
		font-size: 14px !important;
	}

	.size-12 {
		max-width: 12px !important;
		max-height: 12px !important;
	}
}

ion-checkbox.checkbox-checked {
	--border-width: 3px;
}

.btn-actes validate-actes {
	ion-list.align-top {
		background: transparent;

		ion-item {
			--background: transparent !important;
			background: transparent !important;
		}
	}

	.fabs-wrap {
		margin-bottom: -64px;
		margin-top: 4px;
	}
}

.bloc-actes {
	.mat-expansion-panel-content {
		background: white;
		border-radius: 0 0 20px 20px;
		box-shadow: var(--calao-shadow);
	}
}

.surveillance-list {
	.mat-expansion-panel-body {
		padding: 0 0 14px;
	}
}

/** Affichage informations séance, facture */
.intervenants-wrapper {
	display: flex;
	height: 38px;

	.intervenant-count {
		background-color: lightgrey;
		border-radius: 50%;
		color: white;
		width: 40px;
		height: 40px;
		padding-top: 10px;
		padding-left: 24px;
		margin-left: -25px;
		font-size: 0.7rem;
	}
}

.intervenant-area {
	display: flex;
	flex-direction: row;
	justify-content: end;
	margin-right: 8px;

	.intervenants-labels {
		display: flex;
		flex-direction: column;

		ion-note {
			padding-left: 8px;
			color: var(--ion-color-primary);
		}

		ion-label {
			padding-left: 8px;
			font-size: 14px;
			color: var(--ion-color-dark);
			max-width: 320px;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 1;
			overflow: hidden;
		}
	}
}

.width-eventOccurrence-date {
	background: var(--ion-color-osapp-element);
	width: fit-content;
	padding: 4px 8px;
	border-radius: 8px;

	ion-item {
		font-size: 14px;
	}

	ion-icon {
		margin: 0 4px 0 0;
	}
}

.toolbar-bloc {
	.infos-content {
		background-color: var(--ion-color-background);
		padding: 4px 10px;
	}

	.bloc-infos {
		padding: 4px 12px 10px;
		background: white;
		border-radius: 20px;
		box-shadow: var(--calao-shadow);

		ion-col {
			display: flex;
			flex-direction: column;

			.avatar-infos {
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 4px 4px 0 0;

				.customer-labels {
					display: flex;
					flex-direction: column;

					ion-note {
						padding-left: 8px;
					}

					ion-label {
						padding-left: 8px;
						margin-top: -2px;
						font-size: 18px;
						font-weight: bold;
						color: var(--ion-color-dark-tint);
					}

					.maiden-name {
						font-size: 14px;
						font-style: italic;
						font-weight: normal;
					}
				}
			}
		}

		.customer-tags {
			display: flex;
			flex-direction: row;

			.customer-tag {
				margin-right: 4px;

				.customer-tag-label {
					font-size: 12px;
					margin-top: -4px;
				}
			}
		}
	}
}

/* Fixe la largeur de toutes les popup */
.alert-wrapper {
	min-width: 280px !important;
	max-width: 80% !important;
}

super-tabs-container {
	max-width: 100%;
}

.rounded-popup-button {
	--border-radius: 50px !important;
	border-radius: 50px !important;
	box-shadow: var(--calao-shadow) !important;
	background-color: var(--ion-color-primary) !important;

	.alert-button-inner {
		color: var(--ion-color-primary-contrast) !important;
	}
}

.participation-confirmation-popup {
	.disflex {
		display: flex;
		width: 100%;
		align-items: end;
		margin-top: 3px;
	}

	ion-icon {
		width: 24px;
		height: 24px;
		margin-right: 5px;

		&.place {
			color: var(--ion-color-event);
		}
	}
}

/* Affichage formulaire de création de mot de passe */
.create-password-title {
	ion-label {
		font-family: "New Hero Bold";
	}

	ion-note {
		font-size: 14px !important;
		color: var(--ion-color-dark) !important;
	}
}

/* Mise en forme item ToDo */
trade-todo-item {
	calao-list-item .content-wrapper {
		width: 100%;

		calao-list-item-start {
			width: 41px;
		}

		calao-list-item-content {
			width: calc(100% - 41px) !important;

			.content-wrapper {
				width: calc(100% - 40px);
			}
		}
	}
}

//#region business-activities-slide

business-activities-slide {
	.blank-information .content .title-icon .icon-content {
		background-color: var(--ion-color-background) !important;
	}

	trade-activities-list {
		.end-btn {
			margin-bottom: 70px !important;
		}
	}
}

//#endregion business-activities-slide

//#region trade-activities-list

.blank-information {
	width: 100%;
	height: fit-content;
	margin-top: 50px !important;
}

@media (max-height: 700px) {
	trade-activities-list {
		.end-btn {
			margin-bottom: 0 !important;
		}
	}
}

//#endregion trade-activities-list

.modal-rounded::part(content) {
	--border-radius: 14px;
}

.col-table {
	display: table-cell;
	width: 50%;
	background-color: rgba(41, 74, 113, 0.05);
	position: relative;
}

.row-table {
	width: 100%;
	display: table;
	border-spacing: 4px;
	margin-bottom: -2px;
	height: 115px;
}

.dashboard-title {
	--padding-start: 0;

	h5 {
		font-size: 14px;
		font-family: "New Hero Bold";
		color: var(--ion-color-dark);
		display: contents;
	}
}

ion-input {
	.input-wrapper {
		width: 100%;
	}
}

.header-md {
	box-shadow: none !important;
}

calao-doc-explorer-slide,
calao-doc-picker {
	.folder {
		background-color: var(--ion-color-background) !important;
	}

	.not-read {
		.header {
			background-color: var(--ion-color-primary-contrast) !important;
		}
	}
}

business-activities-slide {
	trade-late-item,
	trade-todo-item {
		--ion-color-primary-contrast: var(--ion-color-background)
	}
}

@import "./board.scss";