:root {

	/* Tailles */
	--tile-width: 100%;
	--tile-radius: 14px;
	--title-size: 16px;
	--header-icon-container-size: 38px;
	--header-icon-size: 22px;
	--progress-bar-height: 6px;
	--mdc-linear-progress-active-indicator-height: var(--progress-bar-height) !important;
	--mdc-linear-progress-track-height: var(--progress-bar-height) !important;
	--progress-bar-height: 6px;

	/* Couleurs */
	--tile-background-color: #ffffff;
	--default-icon-color: #9AA9FF;
	--default-icon-bg-color: rgba(154, 169, 255, 0.2);
	--icon-opportunity-color: #B10031;
	--icon-opportunity-bg-color: rgba(177, 0, 49, 0.2);
	--icon-event-color: #A5E336;
	--icon-event-bg-color: rgba(165, 227, 54, 0.15);
	--color-black: #1E2331;
	--indicator-success-color: #18d495;
	--indicator-success-bg-color: rgba(24, 212, 149, 0.2);
	--indicator-warning-color: #ffcd38;
	--indicator-warning-bg-color: rgba(255, 205, 56, 0.2);
	--indicator-danger-color: #f64445;
	--indicator-danger-bg-color: rgba(246,68,69, 0.2);
	--separator-color: rgba(140,143,152, 0.2);

	/* Font */
	--title-font: "New Hero Bold";

	/* Disposition */
	--indicator-circle-margin-top: 8px;
	// Marge appliquée sur les tuile en colonne.
	--tile-col-margin: 5px;
}



.stats-tile {
	display: flex;
	width: var(--tile-width) !important;
	max-width: calc(var(--tile-width) - 20px);
	height: fit-content;
	background-color: var(--tile-background-color) !important;
	border-radius: var(--tile-radius);
	padding: 10px 10px 20px 10px;
	box-shadow: var(--calao-shadow);
	margin: 10px 10px 10px 10px;

	&.width-50pc {
		margin-top: 0;
		margin-bottom: 0;
		// Calcul de la taille de la tuile (toute la largeur du device / 2) - (toutes les marges de la tuile)
		width:  calc((var(--tile-width) / 2) - 15px) !important;
		max-width:  calc((var(--tile-width) / 2) - 15px) !important;

		&.margin-right {
			margin-right: var(--tile-col-margin);
		}

		&.margin-left {
			margin-left: var(--tile-col-margin);
		}
	}
}

.stats-tile-header {
	display: flex;
	width: 100%;
	flex-direction: row;
}

.stats-tile-columns > formly-group:first-of-type {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-top: 10px;
}

.stats-tile-column {
	formly-group:first-of-type {
		display: flex;
		flex-direction: column;
		width: 100%;
		margin-top: 10px;
	}

	.main-container {
		margin-bottom: 10px;
		width: 90%;
	}
}

.stats-tile-row > formly-group:first-of-type {
	display: flex;
	flex-direction: row;
}

trade-statistics {
	form {
		background: unset !important;
	}
}

calao-stat-tile-header {
	.main-container {
		.icon-container {
			border-radius: var(--tile-radius);
			background-color: var(--default-icon-bg-color);
			width: var(--header-icon-container-size);
			height: var(--header-icon-container-size);
			padding: calc((var(--header-icon-container-size) - var(--header-icon-size)) / 2);

			ion-icon {
				width: var(--header-icon-size);
				height: var(--header-icon-size);
				color: var(--default-icon-color);
			}

			&.opportunity{
				ion-icon {
					color: var(--icon-opportunity-color);
				}
				background-color: var(--icon-opportunity-bg-color);
			}

			&.event{
				ion-icon {
					color: var(--icon-event-color);
				}
				background-color: var(--icon-event-bg-color);
			}
		}

		.title-container {
			ion-label {
				font-family: var(--title-font);
				font-size: var(--title-size);
				color: var(--color-black);
				padding: 2px 0 0 5px;
			}

			ion-icon {
				width: 16px;
				height: 16px;
			}
		}
	}
}

calao-stat-achievement-indicator {
	.main-container {
		padding: 0 5px;

		.title {
			font-family: var(--title-font);
			font-size: var(--title-size);
			line-height: var(--title-size);
			color: var(--color-black);
			margin: 6px 0 4px 0;
		}
		.label {
			font-size: 14px;
			line-height: 14px;
			color: var(--ion-color-medium);
			min-height: 28px;
			width: 100%;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			overflow: hidden;
		}

		.progress-container {
			margin-top: var(--indicator-circle-margin-top);

			.progress-bar {
				height: var(--progress-bar-height);
				border-radius: calc(var(--progress-bar-height) / 2);
				max-width: 100%;
			}

			.progress-bar-background {
				width: 100%;
			}
		}
	}
}

calao-stat-ratio-indicator {
	.main-container {
		.progress-container {
			margin-top: var(--indicator-circle-margin-top);
		}

		.title {
			font-family: var(--title-font);
			font-size: var(--title-size);
			line-height: var(--title-size);
			color: var(--color-black);
		}
	}
}

@media screen and (max-width: 390px) {

	formly-field {
		width: 100%;
	}

	.stats-tile {
		width: calc(var(--tile-width) - 10px) !important;
		max-width: none;
		margin: 10px 5px 10px 5px;

		&.width-50pc {
			width:  calc((var(--tile-width) / 2) - 7px) !important;
			max-width:  calc((var(--tile-width) / 2) - 7px) !important;
			margin-top: 0;
			margin-bottom: 0;

			&.margin-right {
				margin-right: 2px;
			}

			&.margin-left {
				margin-left: 2px;
			}
		}
	}

	.stats-tile-columns > formly-group:first-of-type {
		flex-direction: column;
	}
}

@media screen and (min-width: 826px) {

	.stats-board > formly-group:first-child {
		display: flex;
		width: 100%;
		flex-wrap: wrap;
	}

	.stats-tile {
		max-width: 390px;

		&.width-50pc {
			width:  190px !important;
			max-width:  190px !important;
		}
	}
}

//#region board_main

.main-board {
	formly-field {
		background: var(--ion-color-background);
	}
}

.dashboard-tile {
	display: block;
	width: 100%;
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 1px;
	background: var(--ion-color-background);
}

.padding-bottom-8 {
	padding-bottom: 8px;
}

//#endregion