.ellipsis-1 {
	width: 100%;
	display: -webkit-box !important;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	overflow: hidden;
	white-space: break-spaces;
}

.ellipsis-fit-content-1 {
	width: fit-content !important;
	display: -webkit-box !important;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	overflow: hidden;
	white-space: break-spaces;
}